import moment from "moment";
import { Business, Offer, Status } from "../types/generated-types";

// ACTIVE BUSINESS
// Has at least one location with at least one offer that is APPROVED & valid
export const getActiveBusinesses = (businesses: Business[]): Business[] =>
  businesses.filter(b =>
    b.locations?.some(l =>
      l.offers?.some(o => isOfferValidAndMatchingStatus(o, Status.Approved))
    )
  );

// PENDING BUSINESS
// Does NOT have at least one location with at least one offer that is APPROVED & valid
// Has at least one location with at least one offer that is PENDING & valid
export const getPendingBusinesses = (businesses: Business[]): Business[] =>
  businesses
    .filter(
      b =>
        !b.locations?.some(l =>
          l.offers?.some(o => isOfferValidAndMatchingStatus(o, Status.Approved))
        )
    )
    .filter(b =>
      b.locations?.some(l =>
        l.offers?.some(o => isOfferValidAndMatchingStatus(o, Status.Pending))
      )
    );

// NO DEALS BUSINESS
// Does NOT have at least one location with at least one offer that is APPROVED & valid
// Does NOT have at least one location with at least one offer that is PENDING & valid
export const getNoDealsBusinesses = (businesses: Business[]): Business[] =>
  businesses
    .filter(
      b =>
        !b.locations?.some(l =>
          l.offers?.some(o => isOfferValidAndMatchingStatus(o, Status.Approved))
        )
    )
    .filter(
      b =>
        !b.locations?.some(l =>
          l.offers?.some(o => isOfferValidAndMatchingStatus(o, Status.Pending))
        )
    );

export const getBusinessesBySearchQuery = (
  businesses: Business[],
  query: string
): Business[] =>
  businesses.filter(b => b.name.toLowerCase().includes(query.toLowerCase()));

const isOfferValidAndMatchingStatus = (o: Offer, status: Status) =>
  (!o.validTo || moment().isBefore(o.validTo)) &&
  (!o.validFrom || moment().isAfter(o.validFrom)) &&
  o.status === status;
