import { isArray, isPlainObject } from "lodash";

type RemoveKey = "__typename" | "redemptions" | "createdAt";

export function cloneWithout<T>(
  removeKey: RemoveKey,
  obj: T
): Omit<T, RemoveKey> {
  if (!isPlainObject(obj)) {
    return obj;
  }
  const result: any = {};
  for (const key in obj) {
    if (key === removeKey) {
      continue;
    }
    const value = (obj as any)[key];
    if (isPlainObject(value)) {
      result[key] = cloneWithout(removeKey, value);
    } else if (isArray(value)) {
      result[key] = value.map(v => cloneWithout(removeKey, v));
    } else {
      result[key] = value;
    }
  }
  return result;
}

export function cloneWithoutKeys<T>(
  removeKeys: RemoveKey[],
  obj: T
): Omit<T, RemoveKey> {
  let result = obj;
  for (const key of removeKeys) {
    result = cloneWithout(key, result) as T;
  }
  return result;
}
