import firebase from "firebase";
import React, { useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { useMapDispatch } from "../../store";

const VerifyEmail: React.FC = () => {
  const { search } = useLocation();
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const query = new URLSearchParams(search);
  const oobCode = query.get("oobCode");
  const emailVerified = firebase.auth().currentUser?.emailVerified;
  const { signOut } = useMapDispatch(dispatch => ({
    signOut: dispatch.user.signOut
  }));

  useEffect(() => {
    (async () => {
      if (!emailVerified) {
        try {
          await firebase.auth().applyActionCode(oobCode || "");
          setSuccess(true);
          await signOut();
        } catch (e) {
          setError(e.message);
        }
      }
    })();
  }, [oobCode]);

  if (emailVerified) {
    return <Redirect to="/account" />;
  }

  return (
    <div className="contact">
      <h1>Verifying Email</h1>
      {error && <div className="loginFormWrongPassword">{error}</div>}
      {success && <div>Email has been successfully verified!</div>}
    </div>
  );
};

export default VerifyEmail;
