import React from "react";
import { useField, FieldAttributes } from "formik";
import { FormControlLabel, Switch } from "@material-ui/core";

const SwitchField: React.FC<FieldAttributes<any>> = ({ label, ...props }) => {
  const [field, , stuff] = useField(props);
  return (
    <FormControlLabel
      label={label}
      control={
        <Switch
          size="small"
          {...field}
          checked={!field.value}
          onChange={() => stuff.setValue(!field.value)}
        />
      }
    />
  );
};

export default SwitchField;
