import React, { useEffect, useState } from "react";

import "./DealFormTemplate.css";
import NameTitleHeader from "../../common/headers/NameTitleHeader";
import AddLocationFooter from "../../locations/addLocation/AddLocationFooter";
import AddLocationPageIndicator from "../../locations/addLocation/AddLocationPageIndicator";
import { Form, Formik } from "formik";
import {
  OfferFormValues,
  PromoType,
  Redeemability
} from "../../../types/offer";
import { OfferType, RedemptionType } from "../../../types/generated-types";
import moment from "moment";
import * as Yup from "yup";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { onSubmitFn } from "../fields/FormikTypes";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { DealForm } from "./DealForm";
import { Paper, Tab, Tabs } from "@material-ui/core";
import { ToggleView } from "../layout/ToggleView";
import { PromoCodesForm } from "./PromoCodesForm";
import SecondaryButton from "../buttons/SecondaryButton";
import { IMAGE_TYPES } from "./DealImageForm";
import isEqual from "lodash/isEqual";
import omit from "lodash/omit";

interface DealFormTemplateProps {
  name?: string;
  title: string;
  onButtonClick: () => void;
  onAddNew: () => void;
  buttonText: string;
  onSubmit: (values: OfferFormValues) => void;
  submitButtonText: string;
  sideList: JSX.Element;
  activeIndex?: number;
  numOfLines?: number;
  locationId?: number;
  editInputs?: OfferFormValues;
}

export const TAB_INDEX = {
  REGULAR: 0,
  PROMO: 1
};

const DealFormTemplate: React.FC<DealFormTemplateProps> = ({
  name,
  title,
  onButtonClick,
  onAddNew,
  buttonText,
  onSubmit,
  submitButtonText,
  sideList,
  numOfLines,
  activeIndex,
  locationId,
  editInputs
}) => {
  const hasNonMerchantPrivileges = useSelector(
    (state: RootState) => state.user.privileges.hasNonMerchantPrivileges
  );
  const [tab, setTab] = useState(TAB_INDEX.REGULAR);

  useEffect(() => {
    setTab(editInputs?.promo ? TAB_INDEX.PROMO : TAB_INDEX.REGULAR);
  }, [editInputs]);

  const initialValues = {
    title: "",
    description: "",
    disclaimer: "",
    type: OfferType.Percent,
    redemptionType: RedemptionType.Continuous,
    expiration: moment()
      .add(1, "year")
      .format("MM-DD-YYYY"),
    location: locationId || 0,
    value: "",
    redeemability: Redeemability.Free,
    categories: [],
    imageType: IMAGE_TYPES.CHOOSE_FROM_LOCATION,
    images: [],
    multiplePromoCodes: "",
    promo:
      tab === TAB_INDEX.REGULAR
        ? undefined
        : {
            promoType: PromoType.UNIVERSAL_CODE,
            redeemUrl: "",
            promoCodes: []
          }
  };

  const customDirty = (values: any, initialObject: any): boolean => {
    const valuesToValidate = omit(values, ["imageType", "multiplePromoCodes"]);
    const dirtyValues = Object.entries(valuesToValidate).filter(
      ([key, obj]) => !isEqual(obj, initialObject[key])
    );
    return !!dirtyValues.length;
  };

  const fieldRequiredMessage = "This is a required field";

  // admins can add multiple deal categories, but merchants can only add 1. however, we should only enforce the max 1
  //  validation when they are creating a new deal or editing a deal that an admin has not already added multiple
  //  categories to. otherwise they'll be blocked from editing the deal.
  let categoriesValidation = Yup.array()
    .required(fieldRequiredMessage)
    .min(1);
  if (
    !hasNonMerchantPrivileges &&
    (!editInputs?.categories?.length || editInputs?.categories?.length <= 1)
  ) {
    categoriesValidation = categoriesValidation.max(
      1,
      "You can only select 1 category per deal."
    );
  }

  const validationSchema = Yup.object({
    title: Yup.string().required(fieldRequiredMessage),
    description: Yup.string(),
    disclaimer: Yup.string(),
    type: Yup.string().required(fieldRequiredMessage),
    redemptionType: Yup.string().required(fieldRequiredMessage),
    expiration: Yup.string()
      .matches(/\d{1,2}-\d{1,2}-\d{4}/, "Please have in mm-dd-yyyy format")
      .required(fieldRequiredMessage),
    location: Yup.number().required(fieldRequiredMessage),
    value: Yup.string()
      .min(1)
      .required(fieldRequiredMessage),
    redeemability: Yup.string().required(fieldRequiredMessage),
    images: Yup.array().min(1),
    categories: categoriesValidation,
    ...(tab === TAB_INDEX.PROMO && {
      promo: Yup.object({
        promoType: Yup.string().required(fieldRequiredMessage),
        redeemUrl: Yup.string().required(fieldRequiredMessage),
        promoCodes: Yup.array().min(1, fieldRequiredMessage) // TODO: this blocks submit, but isn't showing validation msg on UI
      })
    })
  });

  const onSubmitFn: onSubmitFn<OfferFormValues> = async (
    formValues,
    { setSubmitting, setFieldValue }
  ) => {
    await onSubmit(formValues);
    setSubmitting(false);
    setFieldValue("multiplePromoCodes", ""); // clear out field used to add new promo codes
  };

  return (
    <Formik<OfferFormValues>
      initialValues={editInputs ?? initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmitFn}
      enableReinitialize
    >
      {({ values, isSubmitting, resetForm }) => (
        <Form>
          <div className="dealFormTemplateContainer">
            <div className="dealFormTemplateFormContainer">
              <NameTitleHeader name={name ?? ""} title={title} />
              <Paper square>
                <Tabs
                  centered
                  value={tab}
                  onChange={(_, newValue) => setTab(newValue)}
                >
                  <Tab label="Regular Deal" disabled={!!editInputs} />
                  <Tab label="Promo Code" disabled={!!editInputs} />
                </Tabs>
              </Paper>
              <ToggleView value={tab} index={TAB_INDEX.PROMO}>
                <PromoCodesForm editInputs={editInputs} />
              </ToggleView>
              <DealForm
                locationId={locationId}
                editInputs={editInputs}
                tab={tab}
              />
            </div>
            <div className="dealFormTemplateOtherLocationsContainer">
              <SecondaryButton
                onClick={() => {
                  onAddNew();
                  resetForm({ values: { ...initialValues } });
                }}
                invert={true}
                border={true}
              >
                + Add New
              </SecondaryButton>
              {sideList}
            </div>
          </div>
          <AddLocationFooter
            buttonText={buttonText}
            onClick={onButtonClick}
            submitButtonText={submitButtonText}
            disableNext={customDirty(values, editInputs ?? initialValues)}
            isSubmitting={isSubmitting}
            middleItems={
              numOfLines ? (
                <AddLocationPageIndicator
                  activeIndex={activeIndex ?? 0}
                  numOfLines={numOfLines ?? 0}
                />
              ) : null
            }
          />
        </Form>
      )}
    </Formik>
  );
};

export default DealFormTemplate;
