import { User } from "../types/generated-types";
import moment from "moment";

const FILENAME = `favit_merchants_${moment().format("MM-DD-YY:HHmm")}.csv`;

export const getMerchantListForExport = (merchants: User[]): any[] => {
  return merchants?.map(merchant => ({
    Email: merchant.email,
    "First Name": merchant.firstName,
    "Last Name": merchant.firstName,
    "Business Name": merchant.business?.name || ""
  }));
};

export const downloadCSV = async (rows: any[]) => {
  const separator = ";";
  const keys: string[] = Object.keys(rows[0]);

  const csvContent = `${keys.join(separator)}\n${rows
    .map(row =>
      keys
        .map(k => {
          let cell = row[k] === null || row[k] === undefined ? "" : row[k];
          cell =
            cell instanceof Date
              ? cell.toLocaleString()
              : cell.toString().replace(/"/g, '""');
          if (cell.search(/("|,|\n)/g) >= 0) {
            cell = `"${cell}"`;
          }
          return cell;
        })
        .join(separator)
    )
    .join("\n")}`;

  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

  if (navigator.msSaveBlob) {
    // In case of IE 10+
    navigator.msSaveBlob(blob, FILENAME);
  } else {
    const link = document.createElement("a");
    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", FILENAME);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};
