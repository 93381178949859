import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as api from "../../api";
import Spinner from "../../components/common/Spinner";
import { AddLocationCard } from "../../components/locations/addLocation/AddLocationCard";
import LocationCard from "../../components/locations/LocationCard";
import LocationsHeader from "../../components/locations/LocationsHeader";
import { RootState } from "../../store";
import { Location } from "../../types/locations";
import "./Locations.css";

const useStyles = makeStyles({
  locationsListContainer: {
    flex: 3,
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap"
  }
});

export const Locations: React.FC = () => {
  const { businessId = "" } = useParams();
  const [fetched, setFetched] = useState<boolean>(false);
  const [fetchedLocations, setFetchedLocation] = useState<Location[]>([]);
  const business = useSelector((rootState: RootState) => rootState.business);
  const hasNonMerchantPrivileges = useSelector(
    (rootState: RootState) => rootState.user.privileges.hasNonMerchantPrivileges
  );

  const classes = useStyles();

  const fetchLocations = async () => {
    const queryId = hasNonMerchantPrivileges
      ? parseInt(businessId, 10)
      : business.id;
    if (!queryId) {
      return;
    }
    try {
      const {
        data: { locations }
      } = await api.locations.getLocations(queryId);
      setFetchedLocation(locations);
      setFetched(true);
    } catch (error) {
      console.log(`Error fetching locations for business id ${queryId}`, error);
    }
  };

  useEffect(() => {
    fetchLocations();
  }, []);

  const ListArea = () =>
    !fetched ? (
      <Spinner center />
    ) : fetchedLocations.length === 0 && !hasNonMerchantPrivileges ? (
      <AddLocationCard />
    ) : (
      <>
        {fetchedLocations.map(location => (
          <LocationCard key={location.id} location={location} />
        ))}
      </>
    );

  return (
    <div className="locationsContainer">
      <LocationsHeader locationCount={fetchedLocations.length} />
      <div className={classes.locationsListContainer}>
        <ListArea />
      </div>
    </div>
  );
};
