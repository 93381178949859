import { Button } from "@material-ui/core";
import { FieldAttributes, useField } from "formik";
import React from "react";
import { HoursObj } from "../../../types/generated-types";

import SelectField from "../fields/selectfield/SelectField";
import SwitchField from "../fields/switch/Switch";
import "./LocationForm.css";

const times = [
  { title: "12:00am" },
  { title: "12:30am" },
  { title: "1:00am" },
  { title: "1:30am" },
  { title: "2:00am" },
  { title: "2:30am" },
  { title: "3:00am" },
  { title: "3:30am" },
  { title: "4:00am" },
  { title: "4:30am" },
  { title: "5:00am" },
  { title: "5:30am" },
  { title: "6:00am" },
  { title: "6:30am" },
  { title: "7:00am" },
  { title: "7:30am" },
  { title: "8:00am" },
  { title: "8:30am" },
  { title: "9:00am" },
  { title: "9:30am" },
  { title: "10:00am" },
  { title: "10:30am" },
  { title: "11:00am" },
  { title: "11:30am" },
  { title: "12:00pm" },
  { title: "12:30pm" },
  { title: "1:00pm" },
  { title: "1:30pm" },
  { title: "2:00pm" },
  { title: "2:30pm" },
  { title: "3:00pm" },
  { title: "3:30pm" },
  { title: "4:00pm" },
  { title: "4:30pm" },
  { title: "5:00pm" },
  { title: "5:30pm" },
  { title: "6:00pm" },
  { title: "6:30pm" },
  { title: "7:00pm" },
  { title: "7:30pm" },
  { title: "8:00pm" },
  { title: "8:30pm" },
  { title: "9:00pm" },
  { title: "9:30pm" },
  { title: "10:00pm" },
  { title: "10:30pm" },
  { title: "11:00pm" },
  { title: "11:30pm" }
];

const TimeSelectField: React.FC<FieldAttributes<any>> = ({ ...props }) => {
  return (
    <SelectField
      selectOptions={times}
      defaultOption={{ title: "CLOSED", value: "" }}
      {...props}
    />
  );
};

interface StartToEndTimeProps {
  label: string;
  inputName: string;
}

const StartToEndTime: React.FC<StartToEndTimeProps> = ({
  label,
  inputName
}) => {
  const hoursArrayName = `${inputName}s`;
  const [field, , help] = useField(hoursArrayName);
  return (
    <>
      <div className="timeLabel">{label}</div>
      <div className="timeSwitch">
        <SwitchField name={`${inputName}.isClosed`} label="Open" />
      </div>
      <div className="timeRowContainer">
        {field.value?.map((value: HoursObj, index: number) => {
          const fieldSelector = `${hoursArrayName}.${index}`;
          return (
            <div className="timeRow" key={fieldSelector}>
              <div className="timeColumn">
                <TimeSelectField
                  name={`${fieldSelector}.open`}
                  label="Start Time"
                />
              </div>
              <div className="timeColumn">
                <TimeSelectField
                  name={`${fieldSelector}.close`}
                  label="End Time"
                />
              </div>
              {index ? (
                <Button
                  className="timeButton"
                  onClick={() => {
                    help.setValue([
                      ...field.value.filter((_: any, i: number) => i !== index)
                    ]);
                  }}
                >
                  Remove
                </Button>
              ) : (
                <Button
                  className="timeButton"
                  onClick={() =>
                    help.setValue([...field.value, { open: "", close: "" }])
                  }
                >
                  Add hours
                </Button>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default StartToEndTime;
