import {
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup
} from "@material-ui/core";
import React, { useEffect } from "react";
import { Field } from "formik";
import * as FormLayout from "../layout/FormLayout";
import TextInput from "../fields/textinput/TextInput";
import { ToggleView } from "../layout/ToggleView";
import { OfferFormValues } from "../../../types/offer";
import { UniversalPromoForm } from "./UniversalPromoForm";
import { MultiplePromosForm } from "./MultiplePromosForm";

const PROMO_TYPES = {
  UNIVERSAL: "UNIVERSAL_CODE",
  MULTIPLE: "MULTIPLE_CODES"
};

interface PromoCodesFormProps {
  editInputs?: OfferFormValues;
}

export const PromoCodesForm = ({ editInputs }: PromoCodesFormProps) => {
  const [promoType, setPromoType] = React.useState(PROMO_TYPES.UNIVERSAL);
  useEffect(() => {
    setPromoType(editInputs?.promo?.promoType || PROMO_TYPES.UNIVERSAL);
  }, [editInputs]);

  return (
    <>
      <h2>
        {promoType === PROMO_TYPES.UNIVERSAL ? "Promo Code" : "Promo Codes"}
      </h2>
      {!editInputs && (
        <>
          <p>
            *Promo codes must be set up in your business&apos;s system before
            they can be uploaded to Favit.
          </p>
          <FormLabel>What kind of promo code do you have?</FormLabel>
          <RadioGroup
            value={promoType}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setPromoType((event.target as HTMLInputElement).value);
            }}
          >
            <Field
              type="radio"
              name="promo.promoType"
              as={FormControlLabel}
              control={<Radio />}
              disabled={!!editInputs}
              value={PROMO_TYPES.UNIVERSAL}
              label="One code that can be redeemed by multiple Favit users"
            />
            <Field
              type="radio"
              name="promo.promoType"
              as={FormControlLabel}
              control={<Radio />}
              disabled={!!editInputs}
              value={PROMO_TYPES.MULTIPLE}
              label="A list of codes that can each be redeemed 1x"
            />
          </RadioGroup>
        </>
      )}
      <ToggleView value={promoType} index={PROMO_TYPES.UNIVERSAL}>
        <UniversalPromoForm />
      </ToggleView>
      <ToggleView value={promoType} index={PROMO_TYPES.MULTIPLE}>
        <MultiplePromosForm editInputs={editInputs} />
      </ToggleView>
      <FormLayout.Column>
        <TextInput
          name="promo.redeemUrl"
          label="Website link where user can apply promo"
        />
      </FormLayout.Column>
      <h2>Deal Details</h2>
    </>
  );
};
