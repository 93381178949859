export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type ApplyPromoCodeInput = {
  code: Scalars["String"];
};

export type ApplyPromoCodePayload = {
  __typename?: "ApplyPromoCodePayload";
  coupon?: Maybe<Coupon>;
};

export type Business = {
  __typename?: "Business";
  id: Scalars["Int"];
  name: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  categories?: Maybe<Array<Maybe<Category>>>;
  phone?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  website?: Maybe<Scalars["String"]>;
  locations?: Maybe<Array<Location>>;
};

export type BusinessInput = {
  name: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  locations?: Maybe<Array<LocationInput>>;
  phone?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  website?: Maybe<Scalars["String"]>;
};

export type Category = {
  __typename?: "Category";
  id: Scalars["Int"];
  name: Scalars["String"];
  imageUrl?: Maybe<Scalars["String"]>;
  color?: Maybe<Scalars["String"]>;
};

export type Coordinates = {
  latitude: Scalars["Float"];
  longitude: Scalars["Float"];
};

export type Coupon = {
  __typename?: "Coupon";
  id: Scalars["ID"];
  amountOff?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  percentOff?: Maybe<Scalars["Int"]>;
};

export type EnrollInput = {
  email: Scalars["String"];
  paymentMethodId: Scalars["String"];
  code?: Maybe<Scalars["String"]>;
  appVersion?: Maybe<Scalars["String"]>;
};

export type EnrollPayload = {
  __typename?: "EnrollPayload";
  membership?: Maybe<Membership>;
};

export type HoursObj = {
  __typename?: "HoursObj";
  open?: Maybe<Scalars["String"]>;
  close?: Maybe<Scalars["String"]>;
  isClosed?: Maybe<Scalars["Boolean"]>;
};

export type HoursObjInput = {
  open?: Maybe<Scalars["String"]>;
  close?: Maybe<Scalars["String"]>;
  isClosed?: Maybe<Scalars["Boolean"]>;
};

export type HoursOfOperation = {
  __typename?: "HoursOfOperation";
  monday?: Maybe<HoursObj>;
  tuesday?: Maybe<HoursObj>;
  wednesday?: Maybe<HoursObj>;
  thursday?: Maybe<HoursObj>;
  friday?: Maybe<HoursObj>;
  saturday?: Maybe<HoursObj>;
  sunday?: Maybe<HoursObj>;
  mondays?: Maybe<Array<Maybe<HoursObj>>>;
  tuesdays?: Maybe<Array<Maybe<HoursObj>>>;
  wednesdays?: Maybe<Array<Maybe<HoursObj>>>;
  thursdays?: Maybe<Array<Maybe<HoursObj>>>;
  fridays?: Maybe<Array<Maybe<HoursObj>>>;
  saturdays?: Maybe<Array<Maybe<HoursObj>>>;
  sundays?: Maybe<Array<Maybe<HoursObj>>>;
};

export type HoursOfOperationInput = {
  monday?: Maybe<HoursObjInput>;
  tuesday?: Maybe<HoursObjInput>;
  wednesday?: Maybe<HoursObjInput>;
  thursday?: Maybe<HoursObjInput>;
  friday?: Maybe<HoursObjInput>;
  saturday?: Maybe<HoursObjInput>;
  sunday?: Maybe<HoursObjInput>;
  mondays?: Maybe<Array<Maybe<HoursObjInput>>>;
  tuesdays?: Maybe<Array<Maybe<HoursObjInput>>>;
  wednesdays?: Maybe<Array<Maybe<HoursObjInput>>>;
  thursdays?: Maybe<Array<Maybe<HoursObjInput>>>;
  fridays?: Maybe<Array<Maybe<HoursObjInput>>>;
  saturdays?: Maybe<Array<Maybe<HoursObjInput>>>;
  sundays?: Maybe<Array<Maybe<HoursObjInput>>>;
};

export type Location = {
  __typename?: "Location";
  id: Scalars["Int"];
  address: Scalars["String"];
  city: Scalars["String"];
  state: Scalars["String"];
  zipcode: Scalars["String"];
  latitude: Scalars["Float"];
  longitude: Scalars["Float"];
  phone?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  website?: Maybe<Scalars["String"]>;
  hours?: Maybe<HoursOfOperation>;
  business: Business;
  name?: Maybe<Scalars["String"]>;
  offers?: Maybe<Array<Offer>>;
  images?: Maybe<Array<Scalars["String"]>>;
  tagline?: Maybe<Scalars["String"]>;
  isFavorite?: Maybe<Scalars["Boolean"]>;
  archivedAt?: Maybe<Scalars["String"]>;
  categories?: Maybe<Array<Category>>;
};

export type LocationInput = {
  businessId: Scalars["Int"];
  name?: Maybe<Scalars["String"]>;
  address: Scalars["String"];
  city: Scalars["String"];
  state: Scalars["String"];
  zipcode: Scalars["String"];
  latitude?: Maybe<Scalars["Float"]>;
  longitude?: Maybe<Scalars["Float"]>;
  phone?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  website?: Maybe<Scalars["String"]>;
  hours?: Maybe<HoursOfOperationInput>;
  images?: Maybe<Array<Scalars["String"]>>;
  categories?: Maybe<Array<Scalars["Int"]>>;
  tagline?: Maybe<Scalars["String"]>;
};

export type Membership = {
  __typename?: "Membership";
  id: Scalars["Int"];
  subscriptionStatus: Scalars["String"];
};

export type MembershipInfo = {
  __typename?: "MembershipInfo";
  priceInDollars: Scalars["Float"];
};

export type MerchantInput = {
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  email: Scalars["String"];
  businessName: Scalars["String"];
};

export type Mutation = {
  __typename?: "Mutation";
  _empty?: Maybe<Scalars["String"]>;
  createBusiness: Business;
  updateBusiness: Business;
  deleteBusiness?: Maybe<Scalars["Int"]>;
  addFavorite?: Maybe<Location>;
  removeFavorite?: Maybe<Location>;
  addFavoriteOffer?: Maybe<Location>;
  removeFavoriteOffer?: Maybe<Location>;
  createLocation: Location;
  updateLocation: Location;
  archiveLocation: Location;
  applyPromoCode: ApplyPromoCodePayload;
  enroll: EnrollPayload;
  createOffer: Offer;
  updateOffer: Offer;
  redeemOffer: Offer;
  approveOffer: Offer;
  denyOffer: Offer;
  archiveOffer: Offer;
  createUser: User;
  createMerchant: User;
  createAdmin: User;
  updateUser: User;
  updateEmail: User;
  addRole: User;
  removeRole: User;
};

export type MutationCreateBusinessArgs = {
  business: BusinessInput;
};

export type MutationUpdateBusinessArgs = {
  businessId: Scalars["Int"];
  business: BusinessInput;
};

export type MutationDeleteBusinessArgs = {
  businessId: Scalars["Int"];
};

export type MutationAddFavoriteArgs = {
  locationId: Scalars["Int"];
};

export type MutationRemoveFavoriteArgs = {
  locationId: Scalars["Int"];
};

export type MutationAddFavoriteOfferArgs = {
  offerId: Scalars["Int"];
};

export type MutationRemoveFavoriteOfferArgs = {
  offerId: Scalars["Int"];
};

export type MutationCreateLocationArgs = {
  location: LocationInput;
};

export type MutationUpdateLocationArgs = {
  id: Scalars["Int"];
  location: LocationInput;
};

export type MutationArchiveLocationArgs = {
  id: Scalars["Int"];
};

export type MutationApplyPromoCodeArgs = {
  input: ApplyPromoCodeInput;
};

export type MutationEnrollArgs = {
  input: EnrollInput;
};

export type MutationCreateOfferArgs = {
  offer: OfferInput;
};

export type MutationUpdateOfferArgs = {
  id: Scalars["Int"];
  offer: UpdateInput;
};

export type MutationRedeemOfferArgs = {
  id: Scalars["Int"];
  promoCodeId?: Maybe<Scalars["Int"]>;
};

export type MutationApproveOfferArgs = {
  id: Scalars["Int"];
};

export type MutationDenyOfferArgs = {
  id: Scalars["Int"];
};

export type MutationArchiveOfferArgs = {
  id: Scalars["Int"];
};

export type MutationCreateUserArgs = {
  user: UserInput;
};

export type MutationCreateMerchantArgs = {
  merchant: MerchantInput;
};

export type MutationCreateAdminArgs = {
  user: UserInput;
  role: RoleInput;
};

export type MutationUpdateUserArgs = {
  userId: Scalars["Int"];
  input: UserInput;
};

export type MutationUpdateEmailArgs = {
  userId: Scalars["Int"];
  newEmail: Scalars["String"];
};

export type MutationAddRoleArgs = {
  userId: Scalars["Int"];
  input: RoleInput;
};

export type MutationRemoveRoleArgs = {
  userId: Scalars["Int"];
  input: RoleInput;
};

export type Offer = {
  __typename?: "Offer";
  id: Scalars["Int"];
  validFrom: Scalars["DateTime"];
  validTo: Scalars["DateTime"];
  title: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  disclaimer?: Maybe<Scalars["String"]>;
  type?: Maybe<OfferType>;
  redemptionType: RedemptionType;
  value?: Maybe<Scalars["Float"]>;
  redeemability: Redeemability;
  redemptions?: Maybe<Array<OfferRedemption>>;
  locations?: Maybe<Array<Location>>;
  business?: Maybe<Business>;
  status: Status;
  archivedAt?: Maybe<Scalars["String"]>;
  categories?: Maybe<Array<Category>>;
  image?: Maybe<Scalars["String"]>;
  promo?: Maybe<OfferPromo>;
  isFavorite?: Maybe<Scalars["Boolean"]>;
  isRedeemable?: Maybe<Scalars["Boolean"]>;
  redeemedAt?: Maybe<Scalars["String"]>;
  promoIdToRedeem?: Maybe<Scalars["Int"]>;
  totalRedemptionsCount?: Maybe<Scalars["Int"]>;
};

export type OfferInput = {
  locationId: Scalars["Int"];
  validFrom: Scalars["DateTime"];
  validTo: Scalars["DateTime"];
  title: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  disclaimer?: Maybe<Scalars["String"]>;
  type: OfferType;
  redemptionType: RedemptionType;
  value: Scalars["Float"];
  redeemability: Redeemability;
  categories?: Maybe<Array<Scalars["Int"]>>;
  image?: Maybe<Scalars["String"]>;
  promo?: Maybe<OfferPromoInput>;
};

export type OfferPromo = {
  __typename?: "OfferPromo";
  id: Scalars["Int"];
  offerId: Scalars["Int"];
  promoType: PromoType;
  redeemUrl?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  promoCodes?: Maybe<Array<Maybe<PromoCode>>>;
};

export type OfferPromoInput = {
  promoType?: Maybe<PromoType>;
  redeemUrl?: Maybe<Scalars["String"]>;
  promoCodes?: Maybe<Array<Maybe<PromoCodeInput>>>;
};

export type OfferRedemption = {
  __typename?: "OfferRedemption";
  id: Scalars["Int"];
  createdAt: Scalars["DateTime"];
  userId: Scalars["Int"];
  offerId: Scalars["Int"];
};

export enum OfferType {
  DollarsOff = "DOLLARS_OFF",
  Bogo = "BOGO",
  Percent = "PERCENT",
  FreeItem = "FREE_ITEM",
  FreeEntry = "FREE_ENTRY"
}

export type PromoCode = {
  __typename?: "PromoCode";
  id: Scalars["Int"];
  offerPromoId: Scalars["Int"];
  code: Scalars["String"];
  createdAt: Scalars["DateTime"];
  redemptions?: Maybe<Array<Maybe<PromoCodeRedemption>>>;
};

export type PromoCodeInput = {
  id?: Maybe<Scalars["Int"]>;
  code: Scalars["String"];
};

export type PromoCodeRedemption = {
  __typename?: "PromoCodeRedemption";
  id: Scalars["Int"];
  userId: Scalars["Int"];
  promoCodeId: Scalars["Int"];
  createdAt: Scalars["DateTime"];
};

export enum PromoType {
  UniversalCode = "UNIVERSAL_CODE",
  MultipleCodes = "MULTIPLE_CODES"
}

export type Query = {
  __typename?: "Query";
  _empty?: Maybe<Scalars["String"]>;
  businesses: Array<Business>;
  categories: Array<Category>;
  locations: Array<Maybe<Location>>;
  location?: Maybe<Location>;
  membershipInfo: MembershipInfo;
  offers: Array<Offer>;
  offer?: Maybe<Offer>;
  userByExtId: User;
  adminUsers: Array<User>;
  merchantUsers: Array<User>;
};

export type QueryLocationsArgs = {
  businessId?: Maybe<Scalars["Int"]>;
  filterOffers?: Maybe<Scalars["Boolean"]>;
  filterRedemptions?: Maybe<Scalars["Boolean"]>;
};

export type QueryLocationArgs = {
  id?: Maybe<Scalars["Int"]>;
  filterRedemptions?: Maybe<Scalars["Boolean"]>;
};

export type QueryOffersArgs = {
  businessId?: Maybe<Scalars["Int"]>;
  locationId?: Maybe<Scalars["Int"]>;
  query?: Maybe<Scalars["String"]>;
  nearby?: Maybe<Coordinates>;
  status?: Maybe<Status>;
  promo?: Maybe<OfferPromoInput>;
};

export type QueryOfferArgs = {
  id: Scalars["Int"];
};

export enum Redeemability {
  Free = "FREE",
  Premium = "PREMIUM"
}

export enum RedemptionType {
  OneTime = "ONE_TIME",
  Continuous = "CONTINUOUS"
}

export enum Role {
  Admin = "ADMIN",
  Merchant = "MERCHANT",
  Consumer = "CONSUMER",
  SalesRep = "SALES_REP",
  SalesManager = "SALES_MANAGER"
}

export type RoleInput = {
  role: Role;
};

export enum Status {
  Pending = "PENDING",
  Approved = "APPROVED",
  Denied = "DENIED"
}

export type UpdateInput = {
  locationId?: Maybe<Scalars["Int"]>;
  validFrom?: Maybe<Scalars["DateTime"]>;
  validTo?: Maybe<Scalars["DateTime"]>;
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  disclaimer?: Maybe<Scalars["String"]>;
  type?: Maybe<OfferType>;
  redemptionType?: Maybe<RedemptionType>;
  value?: Maybe<Scalars["Float"]>;
  redeemability?: Maybe<Redeemability>;
  categories?: Maybe<Array<Scalars["Int"]>>;
  image?: Maybe<Scalars["String"]>;
  promo?: Maybe<OfferPromoInput>;
};

export type User = {
  __typename?: "User";
  id: Scalars["Int"];
  extId: Scalars["String"];
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  email: Scalars["String"];
  roles: Array<UserRole>;
  membership?: Maybe<Membership>;
  business?: Maybe<Business>;
};

export type UserInput = {
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
};

export type UserRole = {
  __typename?: "UserRole";
  role: Role;
};
