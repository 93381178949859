import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import get from "lodash/get";
import omit from "lodash/omit";
import moment from "moment";

import { RootState } from "../../store";
import { Offer, OfferFormValues } from "../../types/offer";
import * as api from "../../api";
import DealFormTemplate from "../../components/forms/offer/DealFormTemplate";
import SimpleOfferList from "../../components/offers/SimpleOffersList";
import omitBy from "lodash/omitBy";
import isNull from "lodash/isNull";
import { cloneWithout } from "../../utils/cloneWithout";
import { DealCreatedDialog } from "./DealCreatedDialog";

class OfferNotFound {
  errorMessage: string;
  constructor(msg: string) {
    this.errorMessage = msg;
  }
}

const DealFormPage: React.FC = () => {
  const { locationId = "", offerId = "" } = useParams();
  const [offers, setOffers] = React.useState<Array<Offer>>([]);
  const [editOfferId, setEditOfferId] = React.useState<number>(
    offerId.length > 0 ? parseInt(offerId, 10) : -1
  );
  const [fetched, setFetched] = React.useState<boolean>(false);
  const business = useSelector((rootState: RootState) => rootState.business);
  const hasNonMerchantPrivileges = useSelector(
    (rootState: RootState) => rootState.user.privileges.hasNonMerchantPrivileges
  );
  const history = useHistory();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogIsEdit, setDialogIsEdit] = useState(false);

  const fetchOffers = async () => {
    const queryId = parseInt(locationId, 10);
    try {
      const fetchOffersVariables = queryId
        ? { locationId: queryId }
        : { businessId: business.id };
      const {
        data: { offers }
      } = await api.offers.fetchOffers(fetchOffersVariables);
      setOffers(offers);
      if (!fetched) setFetched(true);
    } catch (error) {
      console.log(`Error fetching offers for location id ${queryId}`, error);
    }
  };

  React.useEffect(() => {
    fetchOffers();
  }, [locationId]);

  const businessName = hasNonMerchantPrivileges
    ? get(offers, ["0", "business", "name"], "")
    : business.name;

  const addNewOffer = async (values: OfferFormValues) => {
    const offerInput = {
      locationId: values.location,
      validFrom: moment().toISOString(),
      validTo: moment(values.expiration, "MM-DD-YYYY").toISOString(),
      image: values.images?.length > 0 ? values.images[0] : undefined,
      value: parseFloat(values.value),
      ...omit(values, [
        "location",
        "expiration",
        "images",
        "multiplePromoCodes",
        "imageType",
        "value"
      ])
    };
    try {
      const {
        data: { createOffer }
      } = await api.offers.createOffer(offerInput);
      setOffers([...offers, createOffer]);
      onOfferClick(createOffer);

      setDialogIsEdit(false);
      setDialogOpen(true);
    } catch (error) {
      console.log(`Error creating offer`, error);
    }
  };

  const removeOffer = async (offerId: number) => {
    try {
      await api.offers.archiveOffer(offerId);
      const newOffers = offers.filter(o => o.id !== offerId);
      setOffers(newOffers);
    } catch (error) {
      console.log(`Error removing offer`, error);
    }
  };

  const onOfferClick = (offer: Offer) => {
    setEditOfferId(offer.id);
  };

  const updateOffer = async (values: OfferFormValues) => {
    const originalOffer = offers.find(o => o.id === editOfferId);
    if (originalOffer === undefined) {
      throw new OfferNotFound(
        `Offer id ${editOfferId} was not found in offers`
      );
    }

    let newImage = null;
    // need to explicitly clear out image field when user is trying to remove image
    if (originalOffer.image && !values.images?.length) {
      newImage = "";
    } else if (values.images?.length > 0) {
      newImage = values.images[0];
    }

    const offerInput = {
      locationId: values.location,
      validFrom: originalOffer?.validFrom,
      validTo: moment(values.expiration, "MM-DD-YYYY").toISOString(),
      title: values.title !== originalOffer?.title ? values.title : null,
      description:
        values.description !== originalOffer?.description
          ? values.description
          : null,
      disclaimer:
        values.disclaimer !== originalOffer?.disclaimer
          ? values.disclaimer
          : null,
      type: values.type !== originalOffer?.type ? values.type : null,
      redemptionType:
        values.redemptionType !== originalOffer?.redemptionType
          ? values.redemptionType
          : null,
      value:
        parseFloat(values.value) !== originalOffer?.value
          ? parseFloat(values.value)
          : null,
      redeemability:
        values.redeemability !== originalOffer?.redeemability
          ? values.redeemability
          : null,
      categories:
        values.categories !== originalOffer.categories.map(cat => cat.id)
          ? values.categories
          : [],
      image: newImage,
      promo:
        values.promo !== originalOffer.promo
          ? cloneWithout("createdAt", cloneWithout("redemptions", values.promo))
          : null
    };

    try {
      const {
        data: { updateOffer }
      } = await api.offers.updateOffer(
        originalOffer?.id ?? -1,
        omitBy(offerInput, isNull)
      );
      const updatedOffers = offers.map(o =>
        o.id !== updateOffer.id ? o : { ...o, ...updateOffer }
      );
      setOffers(updatedOffers);
      if (!hasNonMerchantPrivileges) setEditOfferId(-1);
      onOfferClick(updateOffer);

      setDialogIsEdit(true);
      setDialogOpen(true);
    } catch (err) {
      console.log(
        `There was a problem performing an update on offer with id: ${originalOffer?.id}`,
        err
      );
    }
  };

  const onButtonClick = () => {
    if (editOfferId !== -1 && !locationId) {
      setEditOfferId(-1);
    } else if (locationId) {
      history.goBack();
    } else {
      history.push("/locations");
    }
  };

  const offerToOfferFormValues = (): OfferFormValues | undefined => {
    if (editOfferId !== -1) {
      const foundOffer = offers.find(o => o.id === editOfferId);
      if (foundOffer) {
        const offer = cloneWithout("__typename", foundOffer);
        return {
          title: offer.title,
          description: offer.description ?? "",
          disclaimer: offer.disclaimer ?? "",
          type: offer.type,
          redemptionType: offer.redemptionType,
          expiration: moment(offer.validTo).format("MM-DD-YYYY"),
          location: offer.locations[0].id,
          value: offer.value ? offer.value.toFixed(2) : "",
          redeemability: offer.redeemability,
          categories: offer.categories.map(cat => cat.id) || [],
          images: offer.image ? [offer.image] : [],
          ...(offer.promo && {
            promo: {
              redeemUrl: offer.promo.redeemUrl || "",
              promoType: offer.promo.promoType,
              promoCodes: offer.promo.promoCodes.map(pc => ({
                ...pc,
                code: pc.code || ""
              }))
            }
          })
        };
      }
    }
    return undefined;
  };

  const editInputs = offerToOfferFormValues();

  return (
    <>
      <DealFormTemplate
        name={businessName}
        title={editOfferId !== -1 ? "Edit Deal" : "Add Deal"}
        onButtonClick={onButtonClick}
        onAddNew={() => setEditOfferId(-1)}
        buttonText="Done"
        onSubmit={editOfferId !== -1 ? updateOffer : addNewOffer}
        submitButtonText={editOfferId !== -1 ? "Save Changes" : "Add Deal"}
        locationId={parseInt(locationId, 10)}
        editInputs={editInputs}
        sideList={
          <SimpleOfferList
            offers={offers}
            removeOffer={removeOffer}
            fetchingOffers={!fetched}
            onOfferClick={onOfferClick}
            selectedId={editOfferId}
          />
        }
        numOfLines={locationId ? 0 : 2}
        activeIndex={locationId ? 0 : 1}
      />
      <DealCreatedDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        locationId={locationId}
        isEditingDeal={dialogIsEdit}
      />
    </>
  );
};

export default DealFormPage;
