import React from "react";

import "./LocationInfo.css";

interface LocationInfoProps {
  address: string;
  city: string;
  state: string;
  zipcode: string;
  phone?: string;
  email?: string;
  website?: string;
  tagline?: string;
  headerTextClassName: string;
}

const LocationInfo: React.FC<LocationInfoProps> = ({
  address,
  city,
  state,
  zipcode,
  phone,
  email,
  website,
  tagline,
  headerTextClassName
}) => {
  return (
    <div className="locationInfoContainer">
      <section className="locationInfoSection">
        <p className="locationInfoText">
          <span className={headerTextClassName}>Address</span>
          <br />
          {address}
          <br />
          {`${city}, ${state} ${zipcode}`}
        </p>
      </section>
      <section className="locationInfoSection">
        <p className="locationInfoText">
          <span className={headerTextClassName}>Contact</span>
          <br />
          {phone ?? "Phone:(555)-555-5555"}
          <br />
          {email ?? "Email: helloworld@yourbusiness.com"}
        </p>
      </section>
      <section className="locationInfoSection">
        <p className="locationInfoText">
          <span className={headerTextClassName}>Website</span>
          <br />
          {website ?? "www.yoursite.com"}
        </p>
      </section>
      {tagline ? (
        <section>
          <p className="locationInfoText">
            <span className={headerTextClassName}>
              Customer Experience Tagline
            </span>
            <br />
            {tagline}
          </p>
        </section>
      ) : null}
    </div>
  );
};

export default LocationInfo;
