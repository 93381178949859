import React, { useContext } from "react";

import SecondaryButton from "./SecondaryButton";
import LoginModal from "../../modals/loginmodal/LoginModal";
import ModalContext from "../../modals/modalcontext/ModalContext";

const LoginModalButton: React.FC = () => {
  const { isOpen, handleOpen, handleClose } = useContext(ModalContext);

  return (
    <>
      <SecondaryButton onClick={handleOpen}>Merchant Login</SecondaryButton>
      <LoginModal open={isOpen} handleClose={handleClose} />
    </>
  );
};

export default LoginModalButton;
