import React from "react";
import { makeStyles } from "@material-ui/core";

import ButtonBase, { BaseButtonProps } from "./ButtonBase";

const purpleHexCode = "#aea7ff";

const makeUseStyle = (invert = false, border = false) =>
  makeStyles({
    root: {
      backgroundColor: invert ? "#fff" : purpleHexCode,
      borderRadius: "4px",
      color: invert ? purpleHexCode : "#fff",
      "&:hover": {
        backgroundColor: "#beb8fe"
      },
      padding: "12px 24px",
      border: border ? "solid 1px " + purpleHexCode : "none"
    },
    label: {
      textTransform: "capitalize"
    },
    text: {
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px"
    }
  });

const SecondaryButton: React.FunctionComponent<BaseButtonProps & {
  invert?: boolean;
  border?: boolean;
}> = ({ children, invert, border, ...props }) => {
  const classes = makeUseStyle(invert, border)();

  return (
    <ButtonBase classes={classes} {...props}>
      {children}
    </ButtonBase>
  );
};

export default SecondaryButton;
