import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  pill: {
    fontSize: "10px",
    borderRadius: "10px",
    color: "#ffffff",
    padding: "3px 8px",
    marginRight: "6px",
    position: "relative",
    top: "-2px"
  }
});

interface PillProps {
  color: "pink" | "gray";
  children: any;
}

const PILL_COLORS = {
  pink: "#ff2a5f",
  gray: "#909090"
};

export const Pill = ({ color, children }: PillProps) => {
  const classes = useStyles();
  return (
    <span
      className={classes.pill}
      style={{ backgroundColor: PILL_COLORS[color] }}
    >
      {children}
    </span>
  );
};
