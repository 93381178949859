import React from "react";
import {
  Card,
  makeStyles,
  CardActionArea,
  CardContent
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

import "./AddLocationCard.css";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

const useStyles = makeStyles({
  card: {
    width: "300px",
    height: "304px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  actionArea: {
    textAlign: "center",
    height: "100%"
  }
});

export const AddLocationCard: React.FC = () => {
  const business = useSelector((rootState: RootState) => rootState.business);
  const history = useHistory();
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardActionArea
        className={classes.actionArea}
        onClick={() => history.push(`/locations/add/${business.id}`)}
      >
        <CardContent>
          <p className="addLocationCardText">
            +<br /> <br /> Add Location
          </p>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
