import React from "react";
import {
  Button,
  Card,
  CardActionArea,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles
} from "@material-ui/core";

import "./SimpleLocationCard.css";
import PrimaryButton from "../forms/buttons/PrimaryButton";
import { Location } from "../../types/locations";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

export interface SimpleLocationCardProps {
  location: Location;
  onLocationClick: Function;
  removeLocation: (id: number) => void;
}

const useStyles = makeStyles({
  card: {
    height: "72px",
    display: "flex",
    margin: "20px 0"
  },
  actionArea: {
    flex: 9,
    height: "100%",
    paddingLeft: "16px"
  }
});

const SimpleLocationCard: React.FC<SimpleLocationCardProps> = ({
  location,
  removeLocation,
  onLocationClick
}) => {
  const hasDeletePrivileges = useSelector(
    (rootState: RootState) => rootState.user.privileges.hasDeletePrivileges
  );
  const [removeLocationDialog, setRemoveLocationDialog] = React.useState(false);
  const classes = useStyles();

  const onDialogClose = () => {
    setRemoveLocationDialog(false);
  };

  const onRemoveClick = () => {
    setRemoveLocationDialog(true);
  };

  const remove = () => {
    removeLocation(location.id);
    setRemoveLocationDialog(false);
  };

  return (
    <Card className={classes.card}>
      <div className="simpleLocationCardContainer">
        <CardActionArea
          className={classes.actionArea}
          onClick={() => {
            onLocationClick();
          }}
        >
          <span className="simpleLocationCardText">
            {location.name || location.address}
          </span>
        </CardActionArea>
        {hasDeletePrivileges && (
          <div className="simpleLocationCardRemoveContainer">
            <PrimaryButton btnType={"inverted"} onClick={onRemoveClick}>
              Remove
            </PrimaryButton>
          </div>
        )}
      </div>
      <Dialog open={removeLocationDialog} onClose={onDialogClose}>
        <DialogContent>
          <DialogTitle id="alert-dialog-title">
            Are you sure you want to remove this location?
          </DialogTitle>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={remove}>
            Yes
          </Button>
          <Button color="primary" onClick={onDialogClose}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default SimpleLocationCard;
