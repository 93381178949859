import firebase from "firebase";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import PrimaryButton from "../../components/forms/buttons/PrimaryButton";
import TextInput from "../../components/forms/fields/textinput/TextInput";
import * as FormLayout from "../../components/forms/layout/FormLayout";

const PasswordReset: React.FC = () => {
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  if (success) {
    return (
      <div className="contact">
        <h1>Password Reset</h1>
        <p>Your password has been reset.</p>
      </div>
    );
  }

  return (
    <div className="contact">
      <h1>Password Reset</h1>

      <Formik
        initialValues={{
          newPassword: "",
          confirmPassword: ""
        }}
        validationSchema={Yup.object({
          newPassword: Yup.string().required("Required"),
          confirmPassword: Yup.string()
            .required("Required")
            .oneOf([Yup.ref("newPassword")], "Passwords does not match")
        })}
        onSubmit={async ({ newPassword }) => {
          try {
            setError(null);
            await firebase
              .auth()
              .confirmPasswordReset(query.get("oobCode")!, newPassword);
            setSuccess(true);
          } catch (e) {
            console.error(e);
            setError(e.message);
          }
        }}
      >
        {({ isSubmitting, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <FormLayout.Column>
              <TextInput name="newPassword" label="New Password" />
            </FormLayout.Column>
            <FormLayout.Column>
              <TextInput name="confirmPassword" label="Confirm Password" />
            </FormLayout.Column>
            {error && <div className="loginFormWrongPassword">{error}</div>}
            <div className="btnrow" style={{ marginTop: "16px" }}>
              <PrimaryButton
                className="loginbtn"
                type="submit"
                disabled={isSubmitting}
              >
                Submit
              </PrimaryButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PasswordReset;
