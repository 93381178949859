import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

const Spinner: React.FC<{ size?: number; center?: boolean }> = ({
  size,
  center
}) => {
  return (
    <div
      style={
        center
          ? {
              display: "flex",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              height: "100%"
            }
          : {}
      }
    >
      <CircularProgress
        size={size ?? 40}
        color="inherit"
        style={{ color: "#AEA7FF" }}
      />
    </div>
  );
};

export default Spinner;
