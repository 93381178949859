import { gql } from "apollo-boost";

import { client } from "../services/apollo";
import { LocationInput } from "../types/locations";

const LocationFragments = {
  base: gql`
    fragment base on Location {
      id
      address
      city
      state
      zipcode
      latitude
      longitude
      phone
      email
      website
      images
      tagline
      name
      categories {
        id
      }
    }
  `,
  hours: gql`
    fragment hours on Location {
      hours {
        monday {
          isClosed
        }
        tuesday {
          isClosed
        }
        wednesday {
          isClosed
        }
        thursday {
          isClosed
        }
        friday {
          isClosed
        }
        saturday {
          isClosed
        }
        sunday {
          isClosed
        }
        mondays {
          open
          close
        }
        tuesdays {
          open
          close
        }
        wednesdays {
          open
          close
        }
        thursdays {
          open
          close
        }
        fridays {
          open
          close
        }
        saturdays {
          open
          close
        }
        sundays {
          open
          close
        }
      }
    }
  `,
  offers: gql`
    fragment offers on Location {
      offers {
        id
        validFrom
        validTo
        title
        description
        disclaimer
        type
        redemptionType
        redeemability
        status
        value
      }
    }
  `
};

export const ADD_LOCATION = gql`
  mutation AddLocation($location: LocationInput!) {
    createLocation(location: $location) {
      ...base
      ...hours
      offers {
        id
      }
      business {
        id
        name
      }
    }
  }
  ${LocationFragments.base}
  ${LocationFragments.hours}
`;

export const GET_LOCATIONS = gql`
  query GetLocations($businessId: Int!) {
    locations(businessId: $businessId) {
      ...base
      ...hours
      offers {
        id
        validFrom
        validTo
        title
        description
        disclaimer
        type
        redemptionType
        redeemability
        status
        value
      }
      business {
        id
        name
      }
    }
  }
  ${LocationFragments.base}
  ${LocationFragments.hours}
`;

export const GET_LOCATION = gql`
  query GetLocation($locationId: Int!) {
    location(id: $locationId) {
      ...base
      ...hours
      ...offers
      business {
        id
        name
      }
    }
  }
  ${LocationFragments.base}
  ${LocationFragments.hours}
  ${LocationFragments.offers}
`;

export const UPDATE_LOCATION = gql`
  mutation UpdateLocation($id: Int!, $location: LocationInput!) {
    updateLocation(id: $id, location: $location) {
      ...base
      ...hours
      offers {
        id
      }
      business {
        id
        name
      }
    }
  }
  ${LocationFragments.base}
  ${LocationFragments.hours}
`;

export const addLocation = (values: LocationInput) => {
  return client.mutate({
    mutation: ADD_LOCATION,
    variables: { location: values }
  });
};

export const getLocations = (businessId: number) => {
  return client.query({
    query: GET_LOCATIONS,
    variables: { businessId: businessId }
  });
};

export const getLocation = (locationId: number) => {
  return client.query({
    query: GET_LOCATION,
    variables: { locationId: locationId }
  });
};

export const updateLocation = (locationId: number, location: LocationInput) => {
  return client.mutate({
    mutation: UPDATE_LOCATION,
    variables: { id: locationId, location: location }
  });
};

const ARCHIVE_LOCATION = gql`
  mutation ArchiveLocation($id: Int!) {
    archiveLocation(id: $id) {
      id
      archivedAt
    }
  }
`;

export const archiveLocation = (locationId: number) => {
  return client.mutate({
    mutation: ARCHIVE_LOCATION,
    variables: { id: locationId }
  });
};
