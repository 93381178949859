import React from "react";

import "./LocationsHeader.css";
import AddNewButton from "../forms/buttons/AddNewButton";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import * as api from "../../api";
import { useHistory } from "react-router-dom";
import {
  EditBusinessNameDialog,
  EditBusinessNameFormValues
} from "./EditBusinessNameDialog";
import { onSubmitFn } from "../forms/fields/FormikTypes";
import { RootState, useMapDispatch } from "../../store";
import { useSelector } from "react-redux";

interface LocationsHeaderProps {
  locationCount: number;
}

const LocationsHeader: React.FC<LocationsHeaderProps> = ({ locationCount }) => {
  const [editDialogOpen, setEditDialogOpen] = React.useState(false);
  const [removeDialogOpen, setRemoveDialogOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const history = useHistory();
  const hasDeleteBusinessPrivileges = useSelector(
    (state: RootState) => state.user.privileges.hasDeleteBusinessPrivileges
  );
  const business = useSelector((rootState: RootState) => rootState.business);
  const { setBusiness } = useMapDispatch(dispatch => ({
    setBusiness: dispatch.business.setBusiness
  }));

  const handleThreeDotsClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleThreeDotsClose = () => {
    setAnchorEl(null);
  };

  const removeBusiness = async () => {
    await api.businesses.removeBusiness(business.id);
    history.goBack();
  };

  const editBusinessName: onSubmitFn<EditBusinessNameFormValues> = async values => {
    const { data } = await api.businesses.updateBusiness(business.id, values);
    await setBusiness(data.updateBusiness);
    setEditDialogOpen(false);
  };

  return (
    <div className="locationsHeaderContainer">
      <div className="locationsHeaderTitlesContainer">
        <p className="locationsHeaderBusinessName">{business.name}</p>
        <div className="locationsHeaderRow">
          <div className="locationsHeaderTitle">
            <div>Locations</div>
            <div className="locationsHeaderCount">{locationCount}</div>
            <IconButton onClick={handleThreeDotsClick}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              open={open}
              onClose={handleThreeDotsClose}
              anchorEl={anchorEl}
            >
              <MenuItem
                key="edit"
                onClick={() => {
                  setEditDialogOpen(true);
                  handleThreeDotsClose();
                }}
              >
                Edit business name
              </MenuItem>
              {hasDeleteBusinessPrivileges && (
                <MenuItem
                  key="remove"
                  onClick={() => {
                    setRemoveDialogOpen(true);
                    handleThreeDotsClose();
                  }}
                >
                  <span style={{ color: "#FF2A5F" }}>Remove business</span>
                </MenuItem>
              )}
            </Menu>
          </div>
          <div className="locationsHeaderAddNewContainer">
            <AddNewButton pushPath={`/locations/add/${business.id}`} />
          </div>
        </div>
      </div>
      <EditBusinessNameDialog
        isOpen={editDialogOpen}
        onSubmit={editBusinessName}
        onClose={() => setEditDialogOpen(false)}
      />
      <Dialog
        open={removeDialogOpen}
        onClick={() => setRemoveDialogOpen(false)}
      >
        <DialogTitle>Do you really want to remove this Business?</DialogTitle>
        <DialogContent>
          <DialogActions>
            <Button onClick={removeBusiness}>Yes</Button>
            <Button onClick={() => setRemoveDialogOpen(false)}>No</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default LocationsHeader;
