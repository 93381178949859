import React from "react";
import { makeStyles } from "@material-ui/core";

interface Props {
  src: string;
  selected: boolean;
  onSelect: (src: string) => void;
}

const useStyles = makeStyles({
  imageTile: {
    position: "relative",
    height: 64,
    width: 64,
    marginRight: 24,
    borderRadius: "4px",
    cursor: "pointer"
  },
  image: {
    height: 64,
    width: 64,
    objectFit: "cover",
    borderRadius: "4px",
    border: "2px solid #FFF"
  },
  selectedImage: {
    border: "2px solid #FF4A76"
  }
});

export const SelectableImageTile = ({ src, selected, onSelect }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.imageTile} onClick={() => onSelect(src)}>
      <img
        className={`${classes.image} ${selected && classes.selectedImage}`}
        src={src}
        alt={"Thumbnail"}
      />
    </div>
  );
};
