import React from "react";

import AppStoreIcon from "./appStore.png";
import PlayStoreIcon from "./googlePlayStore.png";
import "./AppStoreIcons.css";

const APP_STORE_LINK = "https://apps.apple.com/us/app/favit/id1588812351";
const PLAY_STORE_LINK =
  "https://play.google.com/store/apps/details?id=com.ejd.favit";

const AppStoreIcons = () => (
  <div className="appStoreIconsContainer">
    <a href={APP_STORE_LINK} className="appStoreIconLink">
      <img src={AppStoreIcon} alt="App Store Link" />
    </a>
    <a href={PLAY_STORE_LINK} className="appStoreIconLink">
      <img src={PlayStoreIcon} alt="Google Play Store Link" />
    </a>
  </div>
);

export default AppStoreIcons;
