import { client } from "../services/apollo";
import { gql } from "apollo-boost";

export const getCategories = async () => {
  return client.query({
    query: gql`
      query {
        categories {
          id
          name
        }
      }
    `
  });
};
