import { Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import { Role } from "../../../types/generated-types";
import { RoleInput, UserInput } from "../../../types/user";
import * as api from "../../../api";
import * as FormLayout from "../../../components/forms/layout/FormLayout";
import TextInput from "../../../components/forms/fields/textinput/TextInput";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";
import SelectField from "../../../components/forms/fields/selectfield/SelectField";
import firebase from "firebase";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

type CreateAdminUserValues = UserInput & RoleInput;

const ROLE_TYPES = [
  { title: "Sales Representative", value: Role.SalesRep },
  { title: "Sales Manager", value: Role.SalesManager },
  { title: "Admin", value: Role.Admin }
];

const CreateAdminUserForm: React.FC = () => {
  const [error, setError] = useState<string>();
  const [success, setSuccess] = useState(false);

  const initialValues: CreateAdminUserValues = {
    firstName: "",
    lastName: "",
    email: "",
    role: Role.SalesRep
  };

  const schema = Yup.object({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    email: Yup.string()
      .required()
      .email(),
    role: Yup.string()
      .required()
      .oneOf([Role.Admin, Role.SalesManager, Role.SalesRep])
  });

  const onSubmit = async (values: CreateAdminUserValues) => {
    try {
      setError(undefined);
      setSuccess(false);
      const roleInput: RoleInput = { role: values.role };
      const userInput: UserInput = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email
      };
      await api.users.createAdminUser(userInput, roleInput);
      await firebase.auth().sendPasswordResetEmail(values.email);
      setSuccess(true);
    } catch (e) {
      console.log(`Error creating admin user`, error);
      setError("Error creating user.");
    }
  };

  if (success) {
    return (
      <>
        <DialogTitle>
          User Created
          <CheckCircleIcon
            color="primary"
            style={{ color: "green", verticalAlign: "text-bottom" }}
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            An email has been sent to the user to reset their password.
          </DialogContentText>
        </DialogContent>
      </>
    );
  }

  return (
    <>
      <DialogTitle>Create New User</DialogTitle>
      <DialogContent>
        <Formik<CreateAdminUserValues>
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <FormLayout.Row>
                <FormLayout.Column>
                  <TextInput name="firstName" label="First Name" />
                </FormLayout.Column>
                <FormLayout.Column>
                  <TextInput name="lastName" label="Last Name" />
                </FormLayout.Column>
              </FormLayout.Row>
              <FormLayout.Column>
                <TextInput name="email" label="Email" />
              </FormLayout.Column>
              <FormLayout.Column>
                <SelectField
                  name="role"
                  label="Role"
                  selectOptions={ROLE_TYPES}
                />
              </FormLayout.Column>
              <ul style={{ fontSize: "12px" }}>
                <li>Sales Representatives can add & edit businesses.</li>
                <li>
                  Sales Managers can add & edit businesses, and approve deals.
                </li>
                <li>Admins have all privileges.</li>
              </ul>
              <DialogActions>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
                {error && <p style={{ color: "red" }}>{error}</p>}
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </>
  );
};

export default CreateAdminUserForm;
