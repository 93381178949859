import * as React from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";

import "./Landing.css";
import BusinessProfile from "../images/Business-Profile.png";
import MapSearch from "../images/Map-Search.png";
import SearchByCategories from "../images/Search-by-Categories.png";
import SlideSearch from "../images/Slide-Search.png";
import LeftLandingBody from "../components/landing/LeftLandingBody";
import RightLandingBody from "../components/landing/RightLandingBody";
import AppStoreIcons from "../components/landing/AppStoreIcons";
import { useSelector } from "react-redux";
import { RootState } from "../store";

const Landing: React.FunctionComponent<RouteComponentProps> = () => {
  const history = useHistory();
  const user = useSelector((rootState: RootState) => rootState.user);
  const hasNonMerchantPrivileges = useSelector(
    (rootState: RootState) => rootState.user.privileges.hasNonMerchantPrivileges
  );

  const skipLanding = async () => {
    if (hasNonMerchantPrivileges) {
      history.push("/admin/deals");
    } else {
      history.push("/locations");
    }
  };

  React.useEffect(() => {
    if (user.id) {
      skipLanding();
    }
  }, [user.id]);

  const TITLE = "Become a Local Overnight and Find Your New FAVIT Place!";
  const SUBTITLE =
    "Save time and money while finding the top and unique places, events, restaurants and experiences. Get access to deals and promotions within the following categories: food, drinks, activities, nightlife & events, and lessons & classes. Locals can find new places and activities to enjoy, and visitors can become a local overnight!";

  return (
    <div className="container">
      <div className="landingBodyContainer">
        <div className="leftBodyContainer">
          <LeftLandingBody title={TITLE} subtitle={SUBTITLE} />
          <AppStoreIcons />
        </div>
        <RightLandingBody />
      </div>
      <div className="videoContainer">
        <iframe
          width="100%"
          height="375px"
          src="https://www.youtube.com/embed/BNrlH97StuE"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
        <div>
          <h1>Why Use FAVIT</h1>
          <ul>
            <li>
              Have an easy way to find fun activities, events & unique dining
              experiences!
            </li>
            <li>Get daily discounts that can be redeemed everyday for FREE</li>
            <li>
              Receive Premium offers such as “Buy 1 Get 1” offers, or 50% - 80%
              off
            </li>
            <li>Create your own FAVIT bucket list of adventurous activities</li>
            <li>Discover new places and hidden gems you didn’t know existed</li>
          </ul>
        </div>
      </div>
      <h1 style={{ textAlign: "center" }}>App Screenshots</h1>
      <div className="screenshotContainer">
        <div className="gridItem">
          <h3>Search by Category</h3>
          <img src={SearchByCategories} className="imgStyle" />
        </div>
        <div className="gridItem">
          <h3>Home Page Search</h3>
          <img src={SlideSearch} className="imgStyle" />
        </div>
        <div className="gridItem">
          <h3>Business Profile</h3>
          <img src={BusinessProfile} className="imgStyle" />
        </div>
        <div className="gridItem">
          <h3>Map Search</h3>
          <img src={MapSearch} className="imgStyle" />
        </div>
      </div>
    </div>
  );
};

export default Landing;
