import React from "react";

interface ToggleProps {
  value: number | string;
  index: number | string;
  children: any;
}

export const ToggleView = ({ value, index, children }: ToggleProps) => (
  <div hidden={value !== index}>{value === index && <>{children}</>}</div>
);
