import { Action } from "@rematch/core";
import ApolloClient from "apollo-boost";
import { ErrorResponse } from "apollo-link-error";

import store from "../store";
import { promiseToObservable } from "../utils/promiseToObservable";

export const client = new ApolloClient({
  request: operation => {
    const idToken = store.getState().user.idToken;

    if (idToken) {
      operation.setContext({
        headers: { Authorization: idToken }
      });
    }
  },
  uri: process.env.REACT_APP_BACKEND_URI,
  onError: ({
    graphQLErrors,
    networkError,
    operation,
    forward
  }: ErrorResponse) => {
    console.error(`Error happened while calling the backend`);
    console.log({ operation, networkError, graphQLErrors });

    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        switch (err?.extensions?.code) {
          case "UNAUTHENTICATED": {
            return (promiseToObservable(
              store.dispatch({
                type: "user/getIdToken"
              }) as Promise<Action<any, any>>
            ) as any).flatMap(() => {
              const headers = operation.getContext().headers;

              operation.setContext({
                headers: {
                  ...headers,
                  authorization: store.getState().user.idToken
                }
              });

              return forward(operation);
            });
          }
          case "FORBIDDEN":
            return store.dispatch({ type: "auth/signOut" });
        }
      }
    }
  }
});

client.defaultOptions = {
  query: {
    fetchPolicy: "network-only"
  }
};
