import React from "react";

import "./AddLocationsFormLocationList.css";
import { Location } from "../../../types/locations";
import SimpleLocationCard from "../SimpleLocationCard";
import Spinner from "../../common/Spinner";

interface AddLocationsFormLocationListProps {
  locations: Array<Location>;
  onLocationClick: Function;
  removeLocation: (id: number) => void;
  isFetching?: boolean;
}

const AddLocationsFormLocationList: React.FC<AddLocationsFormLocationListProps> = ({
  locations,
  onLocationClick,
  removeLocation,
  isFetching
}) => {
  const renderLocationCard = (location: Location, index: number) => (
    <SimpleLocationCard
      key={location.id}
      location={location}
      onLocationClick={() => onLocationClick(index)}
      removeLocation={removeLocation}
    />
  );

  return (
    <div className="addLocationsFormLocationListContainer">
      <div className="addLocationsFormLocationListHeaderContainer">
        <h2>Locations ({locations.length})</h2>
      </div>
      <div>
        {isFetching ? <Spinner center /> : locations.map(renderLocationCard)}
      </div>
    </div>
  );
};

export default AddLocationsFormLocationList;
