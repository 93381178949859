import { Form, Formik } from "formik";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { useMapDispatch } from "../../../store";
import ModalContext from "../../modals/modalcontext/ModalContext";
import PrimaryButton from "../buttons/PrimaryButton";
import { onSubmitFn } from "../fields/FormikTypes";

import TextInput from "../fields/textinput/TextInput";
import * as FormLayout from "../layout/FormLayout";

import "./loginform.css";

interface LoginFormValues {
  email: string;
  password: string;
}

export interface LoginFormProps {
  onClickForgotPassword: () => void;
}

const LoginForm: React.FunctionComponent<LoginFormProps> = ({
  onClickForgotPassword
}) => {
  const { handleClose } = useContext(ModalContext);
  const history = useHistory();
  const { login } = useMapDispatch(dispatch => ({
    login: dispatch.user.login
  }));
  const [loginFailed, setLoginFailed] = useState<boolean>(false);

  const onSubmit: onSubmitFn<LoginFormValues> = async (
    { email, password },
    { setSubmitting }
  ) => {
    try {
      if (loginFailed) setLoginFailed(false);
      const user: any = await login({ email, password });
      handleClose();
      setSubmitting(false);
      if (user?.privileges?.hasNonMerchantPrivileges) {
        history.push("/admin/deals/pending");
      } else {
        history.push("/locations");
      }
    } catch (err) {
      if (err?.code === "auth/user-not-found") setLoginFailed(true);
      console.log(err);
    }
  };

  const goToSignup = () => {
    handleClose();
    history.push("/signup");
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Email must be a valid email")
      .required("Required"),
    password: Yup.string().required("Required")
  });

  return (
    <Formik<LoginFormValues>
      initialValues={{ email: "", password: "" }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <FormLayout.Column>
            <TextInput name="email" label="Email" />
          </FormLayout.Column>
          <FormLayout.Column>
            <TextInput name="password" type="password" label="Password" />
          </FormLayout.Column>
          {loginFailed ? (
            <div className="loginFormWrongPassword">
              Incorrect Email or Password
            </div>
          ) : null}
          <div className="btnrow">
            <PrimaryButton
              className="loginbtn"
              btnType={"inverted"}
              onClick={goToSignup}
            >
              Don&apos;t have an account?
            </PrimaryButton>
            <PrimaryButton
              className="loginbtn"
              type="submit"
              disabled={isSubmitting}
            >
              Login
            </PrimaryButton>
          </div>
          <br />
          <div className="forgot-password-row">
            <PrimaryButton
              className="loginbtn"
              btnType={"inverted"}
              onClick={onClickForgotPassword}
            >
              Forgot password?
            </PrimaryButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
