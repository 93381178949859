import React, { useState, useRef, ChangeEvent, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import last from "lodash/last";
import { ImageTile } from "./ImageTile";
import { PreviousImageTile } from "./PreviousImageTile";
import { useField } from "formik";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DraggableProvided
} from "react-beautiful-dnd";
import { uniq } from "lodash";

const IMAGE_EXTENSIONS = ["jpg", "jpeg", "png"];

const useStyles = makeStyles({
  container: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    minHeight: 64
  },
  btnContainer: {
    backgroundColor: "rgba(127, 128, 147, 0.25)",
    "&:hover": {
      backgroundColor: "#ff4a76"
    },
    padding: "12px 24px",
    borderRadius: "4px",
    cursor: "pointer"
  },
  btnContainerDisabled: {
    backgroundColor: "rgba(127, 128, 147, 0.25)",
    padding: "12px 24px",
    borderRadius: "4px"
  },
  btnText: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    textTransform: "none",
    whiteSpace: "nowrap"
  },
  input: {
    display: "none"
  },
  error: {
    height: 32,
    flex: 1,
    display: "flex",
    alignItems: "center"
  },
  errorText: {
    color: "#f44336",
    fontFamily: "Helvetica Neue",
    fontSize: "12px"
  }
});

interface Props {
  name: string;
  multiple?: boolean;
}

const renderImageTile = (
  image: File,
  setImages: React.Dispatch<React.SetStateAction<File[]>>,
  setTest: React.Dispatch<React.SetStateAction<string[]>>
) => {
  const removeImageFile = () => {
    setImages(oldImages => oldImages.filter(i => i.name !== image.name));
  };

  return (
    <ImageTile
      key={`${image.name}-${image.size}`}
      image={image}
      removeImageFile={removeImageFile}
      setUrls={setTest}
    />
  );
};

export const ImagesUploadRow = ({ name, multiple = true }: Props) => {
  const classes = useStyles();
  const [uploadingImages, setImages] = useState<File[]>([]);
  const [errorText, setErrorText] = useState<string>("");
  const input = useRef<HTMLInputElement>(null);
  const [field, meta, helpers] = useField(name);
  const [downloadUrls, setUrls] = useState<string[]>([]);

  useEffect(() => {
    // Would normally NOT do it this way, but formik context doesn't update
    // fast enough/correctly in child components, so have to add urls here.
    if (downloadUrls.length) {
      const newValues = uniq([...field.value, ...downloadUrls]);
      helpers.setValue(newValues);
      setUrls([]); // clear downloadUrls to prevent being uploaded again
    }
  }, [downloadUrls, field]);

  useEffect(() => {
    if (meta.error && meta.touched) {
      setErrorText("Must add at least 1 image");
    }
  }, [meta]);

  const buttonText = multiple ? "Add images" : "Add image";
  const buttonDisabled =
    !multiple && (uploadingImages.length > 0 || field.value?.length > 0);

  const promptUpload = () => {
    if (errorText) {
      setErrorText("");
    }
    input?.current?.click();
  };

  const onInputStateChange = (event: ChangeEvent<HTMLInputElement>) => {
    const numFiles = event?.target?.files?.length || 0;
    for (let index = 0; index < numFiles; index++) {
      const file = event?.target?.files?.item(index);
      const extension = last(file?.name.split("."))?.toLowerCase() || "";
      const isAcceptableFormat = IMAGE_EXTENSIONS.includes(extension);

      // Check if file is png or jpg
      if (isAcceptableFormat && file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = e => {
          const image = new Image();
          if (e.target?.result) {
            image.src = e.target.result.toString();
            image.onload = function() {
              const width = image.width || 0;
              const height = image.height || 0;
              if (width < 640 || height < 400) {
                setErrorText(
                  "Image dimensions should be more than 640px wide and 400px tall"
                );
              } else {
                setImages(images => [...images, file]);
              }
            };
          }
        };
      } else {
        setErrorText("Images must be .jpg, .jpeg, or .png");
      }
    }
  };

  const renderPreviousImage = (
    url: string,
    i: number,
    provided: DraggableProvided
  ) => {
    const removeImageFile = () => {
      const newImages = field.value.filter((u: string) => u !== url);
      helpers.setValue(newImages);
    };

    return (
      <PreviousImageTile
        key={i}
        url={url}
        removeImageFile={removeImageFile}
        provided={provided}
      />
    );
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const restructured = Array.from(field.value);
    const [removed] = restructured.splice(result.source.index, 1);
    restructured.splice(result.destination.index, 0, removed);
    helpers.setValue(restructured);
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleWindowResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <div>
      <div className={classes.container}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" direction="horizontal">
            {provided => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  display: "flex",
                  flexDirection: windowWidth > 768 ? "row" : "column"
                }}
              >
                {field.value.map((url: string, index: number) => (
                  <Draggable key={url} draggableId={url} index={index}>
                    {provided => renderPreviousImage(url, index, provided)}
                  </Draggable>
                ))}
                {provided.placeholder}
                {uploadingImages.map(f =>
                  renderImageTile(f, setImages, setUrls)
                )}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div
          className={
            buttonDisabled ? classes.btnContainerDisabled : classes.btnContainer
          }
          onClick={() => !buttonDisabled && promptUpload()}
        >
          <input
            ref={input}
            type="file"
            className={classes.input}
            onChange={onInputStateChange}
            multiple={multiple}
          />
          <div
            className={classes.btnText}
            style={{ color: errorText ? "#f44336" : "rgba(0, 0, 0, 0.25)" }}
          >
            {buttonText}
          </div>
        </div>
      </div>
      <div className={classes.error}>
        {errorText && <div className={classes.errorText}>{errorText}</div>}
      </div>
    </div>
  );
};
