import * as React from "react";

import "./formlayout.css";

export const Row: React.FunctionComponent = ({ children }) => {
  return <div className="formlayoutrow">{children}</div>;
};

export const Column: React.FC<{ hidden?: boolean }> = ({
  hidden = false,
  children
}) => {
  return (
    <div
      className="formlayoutcolumn"
      style={{ ...(hidden && { display: "none" }) }}
    >
      {children}
    </div>
  );
};

export const Space: React.FC<{ flexSpace: number }> = ({ flexSpace }) => {
  return <div style={{ flex: flexSpace }}></div>;
};
