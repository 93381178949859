import React from "react";

import { useHistory } from "react-router-dom";
import SecondaryButton from "./SecondaryButton";

const AddNewButton: React.FC<{ pushPath: string; attributes?: any }> = ({
  pushPath,
  attributes
}) => {
  const history = useHistory();
  return (
    <SecondaryButton onClick={() => history.push(pushPath)} {...attributes}>
      + Add New
    </SecondaryButton>
  );
};

export default AddNewButton;
