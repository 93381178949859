import React from "react";

import "../offer/DealFormTemplate.css";
import NameTitleHeader from "../../common/headers/NameTitleHeader";
import AddLocationFooter from "../../locations/addLocation/AddLocationFooter";
import AddLocationPageIndicator from "../../locations/addLocation/AddLocationPageIndicator";
import { Form, Formik } from "formik";
import { AddLocationFormValues } from "../../../types/locations";
import { onSubmitFn } from "../fields/FormikTypes";
import * as Yup from "yup";
import LocationForm from "./LocationForm";

interface FormSideListTemplateProps {
  buttonText: string;
  name?: string;
  title: string;
  onButtonClick: () => void;
  onSubmit: onSubmitFn<AddLocationFormValues>;
  submitButtonText: string;
  initialFormValues: AddLocationFormValues;
  sideList: JSX.Element;
  activeIndex?: number;
  numOfLines?: number;
}

const LocationFormTemplate: React.FC<FormSideListTemplateProps> = ({
  name,
  title,
  onButtonClick,
  onSubmit,
  submitButtonText,
  initialFormValues,
  buttonText,
  sideList,
  numOfLines,
  activeIndex
}) => {
  const fieldRequiredMessage = "This is a required field";

  const validationSchema = Yup.object({
    address: Yup.string().required(
      "Please select an address from the dropdown options"
    ),
    city: Yup.string().required(fieldRequiredMessage),
    state: Yup.string()
      .length(2)
      .required(fieldRequiredMessage),
    zipcode: Yup.string()
      .length(5)
      .required(fieldRequiredMessage),
    phone: Yup.string(),
    email: Yup.string().email(),
    website: Yup.string(),
    tagline: Yup.string(),
    name: Yup.string().nullable(),
    images: Yup.array()
      .min(1)
      .required(),
    categories: Yup.array()
      .min(1)
      .required(),
    hours: Yup.object({
      monday: Yup.object({
        open: Yup.string(),
        close: Yup.string(),
        isOpen: Yup.boolean()
      }),
      tuesday: Yup.object({
        open: Yup.string(),
        close: Yup.string(),
        isOpen: Yup.boolean()
      }),
      wednesday: Yup.object({
        open: Yup.string(),
        close: Yup.string(),
        isOpen: Yup.boolean()
      }),
      thursday: Yup.object({
        open: Yup.string(),
        close: Yup.string(),
        isOpen: Yup.boolean()
      }),
      friday: Yup.object({
        open: Yup.string(),
        close: Yup.string(),
        isOpen: Yup.boolean()
      }),
      saturday: Yup.object({
        open: Yup.string(),
        close: Yup.string(),
        isOpen: Yup.boolean()
      }),
      sunday: Yup.object({
        open: Yup.string(),
        close: Yup.string(),
        isOpen: Yup.boolean()
      }),
      mondays: Yup.array(
        Yup.object({
          open: Yup.string(),
          close: Yup.string(),
          isClosed: Yup.boolean()
        })
      ).nullable(),
      tuesdays: Yup.array(
        Yup.object({
          open: Yup.string(),
          close: Yup.string(),
          isClosed: Yup.boolean()
        })
      ).nullable(),
      wednesdays: Yup.array(
        Yup.object({
          open: Yup.string(),
          close: Yup.string(),
          isClosed: Yup.boolean()
        })
      ).nullable(),
      thursdays: Yup.array(
        Yup.object({
          open: Yup.string(),
          close: Yup.string(),
          isClosed: Yup.boolean()
        })
      ).nullable(),
      fridays: Yup.array(
        Yup.object({
          open: Yup.string(),
          close: Yup.string(),
          isClosed: Yup.boolean()
        })
      ).nullable(),
      saturdays: Yup.array(
        Yup.object({
          open: Yup.string(),
          close: Yup.string(),
          isClosed: Yup.boolean()
        })
      ).nullable(),
      sundays: Yup.array(
        Yup.object({
          open: Yup.string(),
          close: Yup.string(),
          isClosed: Yup.boolean()
        })
      ).nullable()
    })
  });

  return (
    <Formik<AddLocationFormValues>
      initialValues={initialFormValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ dirty }) => (
        <Form>
          <div className="dealFormTemplateContainer">
            <div className="dealFormTemplateFormContainer">
              <NameTitleHeader
                name={name ?? "Insert Name here"}
                title={title}
              />
              <LocationForm initialValues={initialFormValues} />
            </div>
            <div className="dealFormTemplateOtherLocationsContainer">
              {sideList}
            </div>
          </div>
          <AddLocationFooter
            buttonText={buttonText}
            disableNext={dirty}
            middleItems={
              numOfLines ? (
                <AddLocationPageIndicator
                  activeIndex={activeIndex ?? 0}
                  numOfLines={numOfLines ?? 0}
                />
              ) : null
            }
            onClick={onButtonClick}
            submitButtonText={submitButtonText}
          />
        </Form>
      )}
    </Formik>
  );
};

export default LocationFormTemplate;
