import React, { useState } from "react";
import ModalContext from "./ModalContext";

const ModalContextProvider: React.FunctionComponent = ({ children }) => {
  const [isOpen, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <ModalContext.Provider value={{ isOpen, handleOpen, handleClose }}>
      {children}
    </ModalContext.Provider>
  );
};

export default ModalContextProvider;
