import React from "react";

import "./AddLocationFooter.css";
import PrimaryButton from "../../forms/buttons/PrimaryButton";
import { LinearProgress } from "@material-ui/core";

interface AddLocationFooterProps {
  buttonText: string;
  disableNext?: boolean;
  isSubmitting?: boolean;
  middleItems?: any;
  onClick?: () => void;
  submitButtonText?: string;
}

const AddLocationFooter: React.FC<AddLocationFooterProps> = ({
  buttonText,
  disableNext,
  isSubmitting,
  middleItems,
  onClick,
  submitButtonText
}) => {
  return (
    <footer className="addLocationFooterContainer">
      <div className="addLocationFooterSpace" />
      <div className="addLocationFooterMiddle">
        {isSubmitting ? (
          <div className="addLocationFooterLoading">
            <LinearProgress color="secondary" />
          </div>
        ) : (
          middleItems
        )}
      </div>
      <div className="addLocationFooterButtonContainer">
        {submitButtonText ? (
          <PrimaryButton
            disabled={isSubmitting}
            className="addLocationFooterButton"
            type="submit"
          >
            {submitButtonText}
          </PrimaryButton>
        ) : null}
        <PrimaryButton
          disabled={disableNext}
          className="addLocationFooterButton"
          onClick={onClick}
        >
          {buttonText}
        </PrimaryButton>
      </div>
    </footer>
  );
};

export default AddLocationFooter;
