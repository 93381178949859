import { Form, Formik } from "formik";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import * as Yup from "yup";
import { useMapDispatch } from "../../../../store";
import { SignupFormValues } from "../../../../types/user";
import PrimaryButton from "../../buttons/PrimaryButton";
import { onSubmitFn } from "../../fields/FormikTypes";
import TextInput from "../../fields/textinput/TextInput";
import * as FormLayout from "../../layout/FormLayout";
import "./signupform.css";

interface Category {
  id: number;
  name: string;
}

const SignupForm: React.FunctionComponent<RouteComponentProps> = ({
  history
}) => {
  const { signUp } = useMapDispatch(dispatch => ({
    signUp: dispatch.user.signUp
  }));

  const onSubmit: onSubmitFn<SignupFormValues> = async (
    values,
    { setSubmitting }
  ) => {
    try {
      await signUp(values);
    } catch (err) {
      switch (err) {
        case "businessNameTakenError":
          // TODO: implement UI effects for businessNameTakenError and change err type to actual type
          break;
        case "emailIsAlreadyInUseError":
          // TODO: implement UI effects for emailIsAlreadyInUseError and change err type to actual type
          break;
        case "serverTimeoutError":
          // TODO: implement UI effects for serverTimeoutError and change err type to actual type
          break;
        default:
          console.log(err);
      }
    }

    setSubmitting(false);
    history.push("/locations");
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Email must be a valid email")
      .required("Required"),
    password: Yup.string()
      .min(8)
      .required("Required"),
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    businessName: Yup.string().required("Required")
  });

  return (
    <Formik<SignupFormValues>
      initialValues={{
        email: "",
        password: "",
        firstName: "",
        lastName: "",
        businessName: ""
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form className="signupform" autoCapitalize="off" autoCorrect="off">
          <FormLayout.Row>
            <FormLayout.Column>
              <TextInput name="businessName" label="Business Name" />
            </FormLayout.Column>
          </FormLayout.Row>
          <FormLayout.Row>
            <FormLayout.Column>
              <TextInput name="firstName" label="First Name" />
            </FormLayout.Column>
            <FormLayout.Column>
              <TextInput name="lastName" label="Last Name" />
            </FormLayout.Column>
          </FormLayout.Row>
          <FormLayout.Column>
            <TextInput name="email" label="Email" />
          </FormLayout.Column>
          <FormLayout.Column>
            <TextInput name="password" type="password" label="Password" />
          </FormLayout.Column>
          <div className="submitbtncontainer">
            <PrimaryButton
              className="submitbtn"
              type="submit"
              disabled={isSubmitting}
            >
              <span className="btntext">Create account</span>
            </PrimaryButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default withRouter(SignupForm);
