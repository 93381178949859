import React from "react";

import "./LeftLandingBody.css";
import phone from "./phone1.png";

interface LeftLandingBodyProps {
  title: string;
  subtitle: string | JSX.Element;
  titleHighlightColor?: string;
}

const LeftLandingBody: React.FC<LeftLandingBodyProps> = ({
  title,
  subtitle,
  titleHighlightColor
}) => {
  return (
    <div className="leftLandingBodyContainer">
      <div className="leftLandingInnerContainer">
        <p>
          <span
            className="title"
            style={
              titleHighlightColor
                ? {
                    boxShadow: `inset 0 -20px 0 ${titleHighlightColor}`
                  }
                : {}
            }
          >
            {title}
          </span>
        </p>
        <div className="mobilePicContainer">
          <img src={phone} />
        </div>
        <span className="subtitle">{subtitle}</span>
      </div>
    </div>
  );
};

export default LeftLandingBody;
