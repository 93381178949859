import React from "react";
import "./NameTitleHeader.css";

interface NameTitleHeaderProps {
  name?: string;
  title?: string;
}

const NameTitleHeader: React.FC<NameTitleHeaderProps> = ({ name, title }) => {
  return (
    <div>
      <p>
        <span className="nameTitleHeaderBusinessName">{name ?? ""}</span>
        <br />
        <br />
        <span className="nameTitleHeaderHeaderText">{title ?? ""}</span>
      </p>
    </div>
  );
};

export default NameTitleHeader;
