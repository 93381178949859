import * as FormLayout from "../layout/FormLayout";
import TextInput from "../fields/textinput/TextInput";
import React from "react";
import { FieldArray } from "formik";

export const UniversalPromoForm = () => {
  return (
    <FormLayout.Column>
      <FieldArray
        name="promo.promoCodes"
        render={() => (
          <TextInput name="promo.promoCodes[0].code" label="Promo Code" />
        )}
      />
    </FormLayout.Column>
  );
};
