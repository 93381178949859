import React, { useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import omit from "lodash/omit";
import { useSelector } from "react-redux";
import firebase from "firebase/app";

import { AccountFormValues } from "../../../types/user";
import TextInput from "../fields/textinput/TextInput";
import PrimaryButton from "../buttons/PrimaryButton";
import { useMapDispatch, RootState } from "../../../store";
import { onSubmitFn } from "../fields/FormikTypes";
import * as FormLayout from "../layout/FormLayout";

import "./accountform.css";

const AccountForm: React.FunctionComponent = () => {
  const history = useHistory();
  const user = useSelector((rootState: RootState) => rootState.user);
  const { signOut, updateUser } = useMapDispatch(dispatch => ({
    signOut: dispatch.user.signOut,
    updateUser: dispatch.user.updateUser
  }));
  const MIN_PASSWORD_SIZE = 8;

  useEffect(() => {
    if (!user.idToken && !user.id) {
      history.push("/");
    }
  }, [user]);

  const validationSchema = Yup.object({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    email: Yup.string()
      .email("Email must be a valid email")
      .required("Required"),
    password: Yup.string().min(
      MIN_PASSWORD_SIZE,
      "Must be at least 8 character"
    )
  });

  const sendEmailVerification = () =>
    firebase.auth().currentUser?.sendEmailVerification();

  const onSubmit: onSubmitFn<AccountFormValues> = async (
    values,
    { setSubmitting, resetForm }
  ) => {
    try {
      if (initialValues.email !== values.email) {
        await firebase.auth().currentUser?.updateEmail(values.email);
        await firebase.auth().currentUser?.sendEmailVerification();
      }
      if (values.password.length >= MIN_PASSWORD_SIZE) {
        await firebase.auth().currentUser?.updatePassword(values.password);
      }

      if (
        initialValues.email !== values.email ||
        initialValues.firstName !== values.firstName ||
        initialValues.lastName !== values.lastName
      ) {
        const withUpdatedEmail = omit(
          { ...values },
          initialValues.email !== values.email
            ? ["password"]
            : ["password", "email"]
        );
        await updateUser({ id: user.id, values: withUpdatedEmail });
      }

      setSubmitting(false);
      resetForm();
    } catch (err) {
      console.log(err);
    }
  };

  const onSignOut = async () => {
    await signOut();
  };

  const isEmailVerified = firebase.auth().currentUser?.emailVerified;
  const initialValues = {
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    password: ""
  };

  return (
    <Formik<AccountFormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ isSubmitting }) => (
        <Form className="accountform" autoCapitalize="off" autoCorrect="off">
          <FormLayout.Row>
            <FormLayout.Column>
              <TextInput name="firstName" label="First Name" />
            </FormLayout.Column>
            <FormLayout.Column>
              <TextInput name="lastName" label="Last Name" />
            </FormLayout.Column>
          </FormLayout.Row>
          <FormLayout.Column>
            <TextInput name="email" label="Email" disabled={!isEmailVerified} />
          </FormLayout.Column>
          <FormLayout.Column>
            {!isEmailVerified ? (
              <span className="emailverifyinfo">
                To change email address, verify your current email first.{" "}
                <a href="javascript:;" onClick={sendEmailVerification}>
                  Resend verification email
                </a>
              </span>
            ) : null}
          </FormLayout.Column>
          <FormLayout.Column>
            <TextInput
              name="password"
              label="Password"
              type="password"
              placeholder={"Change Password"}
            />
          </FormLayout.Column>
          <div className="submitbtncontainer">
            <PrimaryButton
              className="accountbtn"
              type="submit"
              disabled={isSubmitting}
            >
              <span className="btntext">Edit Account</span>
            </PrimaryButton>
            <PrimaryButton
              className="accountbtn"
              btnType="outline"
              onClick={onSignOut}
            >
              <span className="btntext">Logout</span>
            </PrimaryButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AccountForm;
