import { Location } from "./locations";
import { DateTime, Float, Int } from "./general_types";
import { Category, OfferType } from "./generated-types";

export type RedemptionType = "ONE_TIME" | "CONTINUOUS";
export enum Redeemability {
  Free = "FREE",
  Premium = "PREMIUM"
}

export interface Offer {
  id: Int;
  validFrom: string;
  validTo: string;
  title: string;
  description: string;
  disclaimer: string;
  type: OfferType;
  redemptionType: RedemptionType;
  value: Float;
  redeemability: Redeemability;
  redemptions: OfferRedemption[];
  totalRedemptionsCount: number;
  locations: Location[];
  // TODO: replace with business type
  business: any;
  status: string;
  categories: Category[];
  image: string;
  promo: OfferPromo;
}

export type OfferPromo = {
  id: number;
  offerId: number;
  redeemUrl: string;
  promoType: PromoType;
  promoCodes: [PromoCode];
};

export enum PromoType {
  UNIVERSAL_CODE = "UNIVERSAL_CODE",
  MULTIPLE_CODES = "MULTIPLE_CODES"
}

export type PromoCode = {
  id: number;
  offerPromoId: number;
  code: string;
  redemptions: [PromoCodeRedemption];
};

export type PromoCodeRedemption = {
  id: number;
  userId: number;
  promoCodeId: number;
  createdAt: Date;
};

export interface OfferInput {
  locationId: Int;
  validFrom: string;
  validTo: string;
  title: string;
  description: string;
  disclaimer: string;
  type: OfferType;
  redemptionType: RedemptionType;
  value: Float;
  redeemability: Redeemability;
  categories: number[];
  image?: string;
  promo?: OfferPromoInput;
}

export interface OfferPromoInput {
  promoType: PromoType;
  redeemUrl?: string;
  promoCodes: PromoCodeInput[];
}

export interface PromoCodeInput {
  id?: number;
  code: string;
  redemptions?: [PromoCodeRedemption];
  createdAt?: DateTime;
}

export interface OfferRedemption {
  id: Int;
  createdAt: DateTime;
  userId: Int;
  offerId: Int;
}

export interface OfferRedemption {
  id: Int;
  createdAt: DateTime;
  userId: Int;
  offerId: Int;
}

export interface OfferFormValues {
  title: string;
  description: string;
  disclaimer: string;
  type: OfferType;
  redemptionType: RedemptionType;
  expiration: string;
  location: number;
  redeemability: Redeemability;
  value: string;
  categories: number[];
  images: string[];
  promo?: OfferPromoInput;
  multiplePromoCodes?: string;
  imageType?: string;
}

// Exceptions

export class NoOfferFoundException {
  message: string;
  constructor(msg: string) {
    this.message = msg;
  }
}

export class OfferUpdateFailed {
  message: string;
  error: any;
  constructor(msg: string, err: any) {
    this.message = msg;
    this.error = err;
  }
}
