import React from "react";
import Button from "@material-ui/core/Button";

export interface BaseButtonProps {
  className?: string;
  disabled?: boolean;
  type?: "submit" | "reset" | "button";
  onClick?(): void;
}

interface BaseButtonOwnProps {
  classes: any;
}

const ButtonBase: React.FunctionComponent<BaseButtonProps &
  BaseButtonOwnProps> = ({
  children,
  className,
  classes,
  disabled,
  type = "button",
  onClick
}) => {
  return (
    <Button
      type={type}
      className={className}
      classes={{
        root: classes.root,
        label: classes.label,
        text: classes.text
      }}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default ButtonBase;
