import React from "react";
import "./Contact.css";

const Contact: React.FC = () => (
  <div className="contact">
    <h1>Contact Us</h1>
    <p>
      If you have any questions, please reach out to us at{" "}
      <a href="mailto:info@favit.app">info@FAVIT.APP</a>
    </p>
  </div>
);

export default Contact;
