import React, { useEffect, useState } from "react";
import {
  Link,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch
} from "react-router-dom";
import { Button, Checkbox, FormControlLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import "./Deals.css";
import {
  NoOfferFoundException,
  Offer,
  OfferUpdateFailed
} from "../../types/offer";
import { Redeemability, Status } from "../../types/generated-types";
import * as api from "../../api";
import Spinner from "../../components/common/Spinner";
import { useScreenWidth } from "../../hooks/useScreenWidth";
import { cloneWithoutKeys } from "../../utils/cloneWithout";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const Deals: React.FC = () => {
  const { path, url } = useRouteMatch();
  const { pathname } = useLocation();
  const history = useHistory();

  const [activeLink, setActiveLink] = useState<string>("");
  const [offers, setOffers] = useState<Offer[]>([]);
  const [fetched, setFetched] = useState<boolean>(false);

  const pendingOffers = offers.filter(o => o.status === Status.Pending);
  const approvedOffers = offers.filter(o => o.status === Status.Approved);

  const fetchOffers = async () => {
    try {
      const {
        data: { offers }
      } = await api.offers.fetchOffers({});
      setOffers(offers);
      if (!fetched) setFetched(true);
    } catch (error) {
      console.log(`Error fetching offers in admin page`, error);
    }
  };

  useEffect(() => {
    // will pull new offers when switching to pending / approved view
    fetchOffers();
    if (pathname === "/admin/deals/pending" || pathname === "/admin/deals") {
      setActiveLink("pending");

      if (pathname === "/admin/deals") {
        history.replace("/admin/deals/pending");
      }
    }

    if (pathname === "/admin/deals/approved") {
      setActiveLink("approved");
    }
  }, [pathname, url, path]);

  const toggleRedeemability = (redeemability: Redeemability) => {
    const toggled =
      redeemability === Redeemability.Premium
        ? Redeemability.Free
        : Redeemability.Premium;
    return toggled;
  };

  const toggleOfferRedeemability = async (offerId: number) => {
    try {
      const offer = offers.find(o => o.id === offerId);
      if (offer) {
        const {
          data: { updateOffer }
        } = await api.offers.updateOffer(offerId, {
          redeemability: toggleRedeemability(offer?.redeemability),
          ...(offer.promo && {
            promo: cloneWithoutKeys(
              ["createdAt", "redemptions", "__typename"],
              offer.promo
            )
          })
        });
        const updatedOffers = offers.map(o =>
          o.id === offerId ? updateOffer : o
        );
        setOffers(updatedOffers);
      } else {
        throw new NoOfferFoundException(
          "Offer was not found in list of offers"
        );
      }
    } catch (err) {
      throw new OfferUpdateFailed(
        `Offer of id ${offerId} failed to update`,
        err
      );
    }
  };

  const approveOffer = async (offerId: number) => {
    try {
      const {
        data: { approveOffer }
      } = await api.offers.approveOffer(offerId);
      const updatedOffers = offers.map(o =>
        o.id === offerId ? approveOffer : o
      );
      setOffers(updatedOffers);
    } catch (err) {
      console.log(`We were unable to approve offer with id ${offerId}`, err);
    }
  };

  return (
    <div className="container deals-container">
      <div className="deals-inner-container">
        <h1>Deals</h1>
        <div className="deals-nav">
          <ul className="deals-links">
            <li className="deals-item">
              <Link
                className={
                  activeLink === "pending" ? "deals-link-active" : "deals-link"
                }
                to={`${url}/pending`}
              >
                <h6>Pending</h6>
                <button>{pendingOffers.length}</button>
              </Link>
            </li>
            <li className="deals-item">
              <Link
                className={
                  activeLink === "approved" ? "deals-link-active" : "deals-link"
                }
                to={`${url}/approved`}
              >
                <h6>Approved</h6>
                <button>{approvedOffers.length}</button>
              </Link>
            </li>
          </ul>
        </div>
        <Switch>
          <Route path={`${path}/:status`}>
            {!fetched ? (
              <Spinner center />
            ) : (
              <DealsList
                pendingOffers={pendingOffers}
                approvedOffers={approvedOffers}
                toggleOfferRedeemability={toggleOfferRedeemability}
                approveOffer={approveOffer}
              />
            )}
          </Route>
        </Switch>
      </div>
    </div>
  );
};

interface DealsListProps {
  pendingOffers: Offer[];
  approvedOffers: Offer[];
  toggleOfferRedeemability: (offerId: number) => void;
  approveOffer: (offerId: number) => void;
}

const DealsList: React.FC<DealsListProps> = ({
  pendingOffers,
  approvedOffers,
  approveOffer,
  toggleOfferRedeemability
}) => {
  const { status } = useParams();
  return (
    <>
      {status === "pending" ? (
        <DealsByStatus
          status={Status.Pending}
          offers={pendingOffers}
          approveOffer={approveOffer}
          toggleOfferRedeemability={toggleOfferRedeemability}
        />
      ) : (
        <DealsByStatus
          status={Status.Approved}
          offers={approvedOffers}
          toggleOfferRedeemability={toggleOfferRedeemability}
          approveOffer={approveOffer}
        />
      )}
    </>
  );
};

interface DealByStatusProps {
  status: Status;
  offers: Offer[];
  approveOffer: (offerId: number) => void;
  toggleOfferRedeemability: (offerId: number) => void;
}

const DealsByStatus: React.FC<DealByStatusProps> = ({
  status,
  offers,
  toggleOfferRedeemability,
  approveOffer
}) => {
  const deals = offers.filter(o => o.status === status);

  if (!deals || deals?.length === 0) {
    return <div className="empty-table">No {status} deals here</div>;
  } else {
    return (
      <section className="deals-section">
        <header className="table-header">
          <div className="header-col">Deal</div>
          <div className="header-col">Business</div>
          <div className="header-col">Type</div>
          {status === "APPROVED" && (
            <div className="header-col">Redemptions</div>
          )}
          {status === "APPROVED" && <div className="header-col">Premium</div>}
        </header>
        {deals.map((offer: Offer, index: number) => (
          <Deal
            key={index}
            deal={offer}
            status={status}
            approveOffer={approveOffer}
            toggleOfferRedeemability={toggleOfferRedeemability}
          />
        ))}
      </section>
    );
  }
};

// Component for individual deals
interface DealProps {
  deal: Offer;
  status: Status;
  approveOffer: (offerId: number) => void;
  toggleOfferRedeemability: (offerId: number) => void;
}

const buttonStyles = makeStyles({
  approveButton: {
    backgroundColor: "#20E190",
    fontFamily: "Helvetica Neue",
    fontWeight: 500,
    fontSize: "15px",
    lineHeight: "24px",
    color: "#FFFFFF",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#1CCF84"
    },
    margin: "8px, 24px"
  },
  denyButton: {
    fontFamily: "Helvetica Neue",
    fontWeight: 500,
    fontSize: "15px",
    lineHeight: "24px",
    textTransform: "none",
    margin: "8px, 24px"
  }
});

const Deal: React.FC<DealProps> = ({
  deal,
  status,
  approveOffer,
  toggleOfferRedeemability
}) => {
  const { isMobile } = useScreenWidth();
  const classes = buttonStyles();
  const hasApprovePrivileges = useSelector(
    (state: RootState) => state.user.privileges.hasApprovePrivileges
  );
  const dealLink =
    deal.locations && deal.locations.length > 0
      ? `/deal/edit/${deal?.locations[0]?.id}/${deal.id}`
      : "/";

  return (
    <div className="deal-row">
      <Link to={dealLink} className="deal-item">
        {deal.title}
      </Link>
      {deal && deal.business && (
        <Link to={dealLink} className="deal-item">
          {isMobile && <span className="deal-item-label">Business: </span>}
          {deal.business.name}
        </Link>
      )}
      <Link to={dealLink} className="deal-item">
        {isMobile && <span className="deal-item-label">Type: </span>}
        {deal.type}
      </Link>
      {status === "APPROVED" && (
        <>
          <Link to={dealLink} className="deal-item">
            {isMobile && <span className="deal-item-label">Redemptions: </span>}
            {deal.totalRedemptionsCount}
          </Link>
          <div className="deal-item-premium-checkbox">
            <FormControlLabel
              control={
                <Checkbox
                  checked={deal.redeemability === Redeemability.Premium}
                  onChange={() => toggleOfferRedeemability(deal.id)}
                />
              }
              label="Premium"
              classes={{ label: "deal-item-premium-checkbox-label" }}
            />
          </div>
        </>
      )}
      {status === "PENDING" && hasApprovePrivileges && (
        <div className="deal-item-buttons">
          <Button
            variant="contained"
            className={classes.approveButton}
            onClick={() => approveOffer(deal.id)}
          >
            Approve
          </Button>
        </div>
      )}
    </div>
  );
};

export default Deals;
