export enum BusinessStatus {
  All = "ALL",
  Active = "ACTIVE",
  Pending = "PENDING",
  NoDeals = "NO_DEALS"
}

export interface BusinessStatusFilterOption {
  label: string;
  value: BusinessStatus;
}

export const BUSINESS_STATUS_FILTER_OPTIONS: BusinessStatusFilterOption[] = [
  { label: "All", value: BusinessStatus.All },
  { label: "Live", value: BusinessStatus.Active },
  { label: "Waiting Deal Approval", value: BusinessStatus.Pending },
  { label: "No Active Deals", value: BusinessStatus.NoDeals }
];
