import firebase from "firebase";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import PrimaryButton from "../buttons/PrimaryButton";
import TextInput from "../fields/textinput/TextInput";
import * as FormLayout from "../layout/FormLayout";

const ForgotPasswordForm: React.FunctionComponent = () => {
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  if (success) {
    return (
      <p>{`We've emailed you instructions for resetting your password.`}</p>
    );
  }

  return (
    <Formik
      initialValues={{ email: "" }}
      validationSchema={Yup.object({
        email: Yup.string()
          .email("Email must be a valid email")
          .required("Required")
      })}
      onSubmit={async ({ email }) => {
        try {
          setError(false);
          await firebase.auth().sendPasswordResetEmail(email);
          setSuccess(true);
        } catch (e) {
          console.error(e);
          setError(true);
        }
      }}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <FormLayout.Column>
            <TextInput name="email" label="Email" />
          </FormLayout.Column>
          {error && (
            <div className="loginFormWrongPassword">There was an error.</div>
          )}
          <div className="btnrow" style={{ marginTop: "16px" }}>
            <PrimaryButton
              className="loginbtn"
              type="submit"
              disabled={isSubmitting}
            >
              Reset Password
            </PrimaryButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ForgotPasswordForm;
