import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem
} from "@material-ui/core";

import "./Location.css";
import NoMatch from "../../components/NoMatch";
import SimpleOffersList from "../../components/offers/SimpleOffersList";
import HoursOfOperation from "../../components/location/HoursOfOperation";
import LocationInfo from "../../components/location/LocationInfo";
import { ImageViewer } from "../../components/location/ImageViewer";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AddNewButton from "../../components/forms/buttons/AddNewButton";
import * as api from "../../api";
import { Location } from "../../types/locations";
import { Offer } from "../../types/offer";
import Spinner from "../../components/common/Spinner";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const LocationDetails: React.FC = () => {
  const [fetched, setFetched] = useState<boolean>(false);
  const [location, setLocation] = useState<Location>();
  const [offers, setOffers] = React.useState<Array<Offer>>([]);
  const { locationId = "" } = useParams();
  const history = useHistory();
  const hasDeletePrivileges = useSelector(
    (rootState: RootState) => rootState.user.privileges.hasDeletePrivileges
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const fetchLocation = async () => {
    const queryId = parseInt(locationId, 10);
    try {
      const {
        data: { location }
      } = await api.locations.getLocation(queryId);
      setLocation(location);
      // setOffers(location.offers);
      setFetched(true);
    } catch (error) {
      console.log(`Error fetching location with id ${queryId}`, error);
    }
  };

  // TODO: location.offers only includes active offers.
  //  add ability to pass var to backend to include all rather than doing two queries here
  const fetchOffers = async () => {
    const queryId = parseInt(locationId, 10);
    try {
      const {
        data: { offers }
      } = await api.offers.fetchOffers({ locationId: queryId });
      setOffers(offers);
    } catch (error) {
      console.log(`Error fetching offers for location id ${queryId}`, error);
    }
  };

  React.useEffect(() => {
    fetchLocation();
    fetchOffers();
  }, [locationId]);

  const handleThreeDotsClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleThreeDotsClose = () => {
    setAnchorEl(null);
  };

  const [dialogBox, setDialogBox] = useState<boolean>(false);
  const handleDialogOpen = () => {
    setDialogBox(true);
  };
  const handleDialogClose = () => {
    setDialogBox(false);
  };
  const removeLocation = () => {
    if (!location?.id) {
      return;
    }
    api.locations.archiveLocation(location?.id);
    history.goBack();
  };

  const removeOffer = async (offerId: number) => {
    try {
      await api.offers.archiveOffer(offerId);
      const newOffers = offers.filter(o => o.id !== offerId);
      setOffers(newOffers);
    } catch (error) {
      console.log(`Error removing offer`, error);
    }
  };

  const onOfferClick = (offer: Offer) => {
    history.push(`/deal/edit/${location?.id}/${offer.id}`);
  };

  if (!fetched) {
    return <Spinner center />;
  }

  if (!location) {
    return <NoMatch />;
  }

  const editMenuOption = {
    title: "Edit",
    action: () => {
      history.push(`/location/edit/${location.business.id}/${locationId}`);
    },
    textStyle: {}
  };

  const deleteMenuOption = {
    title: "Remove",
    action: () => {
      handleDialogOpen();
      handleThreeDotsClose();
    },
    textStyle: { color: "#FF2A5F" }
  };

  const menuOptions = hasDeletePrivileges
    ? [editMenuOption, deleteMenuOption]
    : [editMenuOption];

  const businessName = location.name || location.business?.name;

  return (
    <div className="locationContainer">
      <div className="locationImagesContainer">
        <ImageViewer images={location.images} />
      </div>
      <div className="locationInfoAndDealsContainer">
        <div className="locationGeneralInfoContainer">
          <div className="locationInfoHeader">
            <h1>{businessName}</h1>
            <IconButton onClick={handleThreeDotsClick}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              open={open}
              onClose={handleThreeDotsClose}
              anchorEl={anchorEl}
            >
              {menuOptions.map(({ title, action, textStyle }) => (
                <MenuItem key={title} onClick={action}>
                  <span style={textStyle}>{title}</span>
                </MenuItem>
              ))}
            </Menu>
          </div>
          <div className="locationInfoBody">
            <LocationInfo
              address={location.address}
              city={location.city}
              state={location.state}
              zipcode={location.zipcode}
              phone={location.phone}
              email={location.email}
              website={location.website}
              tagline={location.tagline}
              headerTextClassName="smallHeaderText"
            />
            <HoursOfOperation
              hours={location.hours}
              headerTextClassName="smallHeaderText"
            />
          </div>
        </div>
        <div className="locationDealsContainer">
          <SimpleOffersList
            offers={offers}
            removeOffer={removeOffer}
            onOfferClick={onOfferClick}
            fetchingOffers={!fetched}
            rightHeaderContent={() => (
              <AddNewButton
                pushPath={`/location/${locationId}/adddeal`}
                attributes={{
                  invert: true,
                  border: true
                }}
              />
            )}
          />
        </div>
      </div>
      <Dialog open={dialogBox} onClose={handleDialogClose}>
        <DialogTitle>Do you really want to remove this Location?</DialogTitle>
        <DialogContent>
          <DialogActions>
            <Button onClick={removeLocation}>Yes</Button>
            <Button onClick={handleDialogClose}>No</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default LocationDetails;
