import { gql } from "apollo-boost";
import { client } from "../services/apollo";
import { BusinessInput } from "../types/generated-types";

export const fetchBusinesses = () => {
  return client.query({
    query: gql`
      query {
        businesses {
          id
          name
          description
          categories {
            id
            name
          }
          phone
          email
          website
          locations {
            id
            images
            archivedAt
            offers {
              status
              validTo
              validFrom
            }
          }
        }
      }
    `
  });
};

export const removeBusiness = (businessId: number) => {
  return client.mutate({
    mutation: gql`
      mutation DeleteBusiness($businessId: Int!) {
        deleteBusiness(businessId: $businessId)
      }
    `,
    variables: { businessId }
  });
};

export const updateBusiness = (businessId: number, business: BusinessInput) => {
  return client.mutate({
    mutation: gql`
      mutation UpdateBusiness($businessId: Int!, $business: BusinessInput!) {
        updateBusiness(businessId: $businessId, business: $business) {
          name
          id
        }
      }
    `,
    variables: { businessId, business }
  });
};
