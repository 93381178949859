import React from "react";
import { makeStyles } from "@material-ui/core";

import ButtonBase, { BaseButtonProps } from "./ButtonBase";

type BtnType = "default" | "inverted" | "outline";
const makeUseStyles = (type: BtnType = "default") => {
  const configMap: { [key: string]: any } = {
    default: {
      backgroundColor: "#ff2a5f",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#ff4a76 !important"
      }
    },
    inverted: {
      backgroundColor: "#fff",
      color: "#ff2a5f",
      "&:hover": {
        backgroundColor: "#fef4f7 !important"
      }
    },
    outline: {
      backgroundColor: "#fff",
      color: "#ff2a5f",
      border: "1px solid #ff2a5f",
      "&:hover": {
        backgroundColor: "#fef4f7 !important"
      }
    }
  };
  return makeStyles({
    root: {
      borderRadius: "4px",
      ...configMap[type]
    },
    label: {
      textTransform: "capitalize"
    },
    text: {
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px"
    }
  });
};

const PrimaryButton: React.FunctionComponent<BaseButtonProps & {
  type?: string;
  btnType?: BtnType;
}> = ({ children, btnType = "default", ...props }) => {
  const classes = makeUseStyles(btnType)();

  return (
    <ButtonBase classes={classes} {...props}>
      {children}
    </ButtonBase>
  );
};

export default PrimaryButton;
