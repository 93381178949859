import * as FormLayout from "../layout/FormLayout";
import TextInput from "../fields/textinput/TextInput";
import React, { useState } from "react";
import { OfferFormValues } from "../../../types/offer";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  makeStyles
} from "@material-ui/core";
import { PromoCodeCard } from "./PromoCodeCard";
import { useFormikContext } from "formik";
import PrimaryButton from "../buttons/PrimaryButton";
import moment from "moment";

interface PromoCodeUniversalFormProps {
  editInputs?: OfferFormValues;
}

const useStyles = makeStyles({
  list: {
    maxHeight: 300,
    overflow: "auto",
    border: "1px solid #909090",
    borderRadius: "5px",
    marginBottom: 20
  }
});

const AddPromosInput = ({ onChange }: any) => (
  <FormLayout.Column>
    <TextInput
      name="multiplePromoCodes"
      label="Promo Codes (separate by comma or newline)"
      onChange={onChange}
      multiline
      rows={6}
    />
  </FormLayout.Column>
);

export const MultiplePromosForm = ({
  editInputs
}: PromoCodeUniversalFormProps) => {
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext<OfferFormValues>();
  const promoCodes = values.promo?.promoCodes || [];
  const editInputPromoCodes = editInputs?.promo?.promoCodes;
  const existingCodesLength = editInputPromoCodes?.length
    ? editInputPromoCodes.length
    : 0;
  const [showInputField, setShowInputField] = useState(false);
  const [promoIdToRemove, setPromoIdToRemove] = useState();

  const mapInputToPromoCodes = (input: string) => {
    setFieldValue("multiplePromoCodes", input);
    return input
      .split(/\r?\n|\r|\n|,/g)
      .map(code => code.trim())
      .filter(c => c) // remove empties
      .forEach((code, index) =>
        setFieldValue(`promo.promoCodes[${existingCodesLength + index}]`, {
          code
        })
      );
  };

  const onChange = (e: any) => mapInputToPromoCodes(e.target.value);

  const onRemoveDialogClose = () => setPromoIdToRemove(undefined);

  const onRemovePromo = () => {
    const idInValueArray = promoCodes?.findIndex(
      pc => pc.id === promoIdToRemove
    );
    if (idInValueArray !== undefined && idInValueArray !== -1) {
      promoCodes?.splice(idInValueArray, 1);
      setFieldValue("promo.promoCodes", promoCodes);
    }
    onRemoveDialogClose();
  };

  if (!editInputPromoCodes?.length) {
    return <AddPromosInput onChange={onChange} />;
  }

  return (
    <>
      <List className={classes.list}>
        {editInputPromoCodes
          .sort((pc1, pc2) => moment(pc1.createdAt).diff(pc2.createdAt))
          .map(pc => (
            <PromoCodeCard
              key={pc.id}
              promoCode={pc}
              onDelete={() => setPromoIdToRemove(pc.id)}
            />
          ))}
      </List>
      <PrimaryButton btnType="inverted" onClick={() => setShowInputField(true)}>
        + Add more codes
      </PrimaryButton>
      {showInputField && <AddPromosInput onChange={onChange} />}
      <Dialog open={!!promoIdToRemove} onClose={onRemoveDialogClose}>
        <DialogContent>
          <DialogTitle>Are you sure you want to remove this Promo?</DialogTitle>
          <DialogContentText>
            Any users who have redeemed this promo will no longer see it on the
            Favit app.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onRemovePromo}>
            Yes
          </Button>
          <Button color="primary" onClick={onRemoveDialogClose}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
