import React, { FC, useEffect, useState } from "react";
import { TextField } from "@material-ui/core";
import { useField, useFormikContext } from "formik";
import { AddLocationFormValues } from "../../../../types/locations";
import { usePlacesWidget } from "react-google-autocomplete";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";

const AddressInput: FC<{ initialValues: AddLocationFormValues }> = ({
  initialValues
}) => {
  const { setFieldValue } = useFormikContext<AddLocationFormValues>();
  const [, meta] = useField("address");
  const hasError = meta.touched && !!meta.error;

  const [autocompleteAddressVal, setAutocompleteAddressVal] = useState("");
  useEffect(() => {
    if (initialValues.address) {
      setAutocompleteAddressVal(
        `${initialValues.address}, ${initialValues.city}, ${initialValues.state} ${initialValues.zipcode}`
      );
    }
  }, [initialValues]);

  const mapPlaceToLocationFields = (place: google.maps.places.PlaceResult) => {
    const address = place.address_components;
    if (!address) {
      return;
    }
    const findIn = (
      address: google.maps.GeocoderAddressComponent[],
      searchCriteria: string[],
      type: "long_name" | "short_name" = "long_name"
    ) =>
      address.find(comp =>
        comp.types.find((type: string) => searchCriteria.includes(type))
      )?.[type] || "";

    const number = findIn(address, ["street_number"]);
    const street = findIn(address, ["street_address", "route"]);
    const city = findIn(address, ["sublocality", "locality"]);
    const zipcode = findIn(address, ["postal_code"]);
    const state = findIn(
      address,
      ["administrative_area_level_1"],
      "short_name"
    );

    setFieldValue("address", `${number} ${street}`);
    setFieldValue("city", city);
    setFieldValue("state", state);
    setFieldValue("zipcode", zipcode);
    setAutocompleteAddressVal(
      `${number} ${street}, ${city}, ${state} ${zipcode}`
    );
  };

  const { ref: autocompleteRef } = usePlacesWidget({
    apiKey: process.env.REACT_APP_API_KEY,
    onPlaceSelected: place => {
      mapPlaceToLocationFields(place);
    },
    options: {
      fields: ["address_components", "formatted_address"],
      componentRestrictions: { country: "us" },
      types: ["address"]
    }
  });

  return (
    <FormControl fullWidth error={hasError}>
      <TextField
        fullWidth
        error={hasError}
        type="search"
        variant="standard"
        inputRef={autocompleteRef}
        label="Address*"
        placeholder="Please select an Address from the dropdown"
        value={autocompleteAddressVal}
        onChange={e => {
          setAutocompleteAddressVal(e.target.value);
        }}
        onBlur={() => {
          setAutocompleteAddressVal("");
          setFieldValue("address", "");
        }}
      />
      <FormHelperText error={hasError}>
        {hasError ? meta.error : null}
      </FormHelperText>
    </FormControl>
  );
};

export default AddressInput;
