import React from "react";
import { Route, RouteProps, Redirect } from "react-router-dom";

import { useSelector } from "react-redux";
import { RootState } from "../store";

interface Props {
  adminRoute?: boolean;
}

const PrivateRoute: React.FunctionComponent<RouteProps & Props> = ({
  children,
  adminRoute,
  ...rest
}) => {
  const idToken = useSelector((state: RootState) => state.user.idToken);
  const hasNonMerchantPrivileges = useSelector(
    (state: RootState) => state.user.privileges.hasNonMerchantPrivileges
  );

  if (adminRoute) {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          idToken && hasNonMerchantPrivileges ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/",
                state: { from: location }
              }}
            />
          )
        }
      />
    );
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        idToken ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
