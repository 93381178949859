import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

import Logo from "./logo.png";
import "./NavBar.css";
import CustomNavLink from "./CustomNavLink";
import LoginModalButton from "../../forms/buttons/LoginModalButton";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { Drawer, IconButton } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";

const GeneralLinks: React.FC = () => {
  return (
    <>
      <CustomNavLink to="/" text="Home" exact={true} />
      <CustomNavLink to="/signup" text="Register Business" />
      <div className="navLinkContainer">
        <LoginModalButton>Merchant Login</LoginModalButton>
      </div>
    </>
  );
};
const UserLinks: React.FC = () => {
  return (
    <>
      <CustomNavLink to="/locations" text="Locations" />
      <CustomNavLink to="/account" text="Account" />
    </>
  );
};

const AdminLinks: React.FC = () => {
  const hasManageUsersPrivileges = useSelector(
    (rootState: RootState) => rootState.user.privileges.hasManageUsersPrivileges
  );
  return (
    <>
      <CustomNavLink to="/admin/deals" text="Deals" />
      <CustomNavLink to="/admin/businesses" text="Businesses" />
      {hasManageUsersPrivileges && (
        <CustomNavLink to="/admin/users" text="Users" />
      )}
      <CustomNavLink to="/account" text="Account" />
    </>
  );
};

const NavBar: React.FC = () => {
  const hasNonMerchantPrivileges = useSelector(
    (rootState: RootState) => rootState.user.privileges.hasNonMerchantPrivileges
  );
  const location = useLocation();
  const onGeneralPage =
    location.pathname === "/" || location.pathname === "/signup";
  const homeLink = hasNonMerchantPrivileges ? "/admin/deals/pending" : "/";
  const [drawerOpen, setDrawerOpen] = useState(false);

  const NavLinks = () =>
    onGeneralPage ? (
      <GeneralLinks />
    ) : hasNonMerchantPrivileges ? (
      <AdminLinks />
    ) : (
      <UserLinks />
    );

  const onDrawerClose = () => setDrawerOpen(false);

  return (
    <nav className="navbar">
      <NavLink to={homeLink} title="FAVIT Homepage" className="logoWrapper">
        <img src={Logo} alt="logo" className="logo" />
        <h3>FAVIT</h3>
      </NavLink>
      <div className="navigationContainer">
        <NavLinks />
      </div>
      <div className="navigationContainerMobile">
        <IconButton onClick={() => setDrawerOpen(true)}>
          <MenuIcon />
        </IconButton>
        <Drawer
          anchor="top"
          open={drawerOpen}
          onClose={onDrawerClose}
          style={{ padding: 100 }}
          classes={{ paper: "navigationContainerDrawer" }}
          PaperProps={{ onClick: onDrawerClose }}
        >
          <NavLinks />
        </Drawer>
      </div>
    </nav>
  );
};

export default NavBar;
