import { useState, useEffect, useMemo } from "react";

const MOBILE_BREAKPOINT = 768;

export const useScreenWidth = () => {
  const [width, setWidth] = useState(window.innerWidth);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  const isMobile = useMemo(() => width <= MOBILE_BREAKPOINT, [width]);

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return {
    isMobile
  };
};
