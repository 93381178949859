import React from "react";
import "./Footer.css";
import { Link, useLocation } from "react-router-dom";

const Footer: React.FC = () => {
  const location = useLocation();
  const path = location?.pathname;

  // only add footer for public paths
  if (
    path === "" ||
    path === "/" ||
    path === "/privacy-policy" ||
    path === "/terms-and-conditions" ||
    path === "/signup"
  ) {
    return (
      <div className="footer">
        <p className="footer-copyright">
          © Favit App Inc. All rights reserved.
        </p>
        <Link to="/privacy-policy" className="footer-link">
          Privacy Policy
        </Link>
        <Link to="/terms-and-conditions" className="footer-link">
          Terms and Conditions
        </Link>
      </div>
    );
  }

  return null;
};

export default Footer;
