import { client } from "../services/apollo";
import { gql } from "apollo-boost";
import { UserInput, RoleInput } from "../types/user";

const USER_FRAGMENT = gql`
  fragment UserFragment on User {
    id
    firstName
    lastName
    email
    roles {
      role
    }
  }
`;

export const getMerchantUsers = async () => {
  return client.query({
    query: gql`
      ${USER_FRAGMENT}
      query {
        merchantUsers {
          ...UserFragment
          business {
            name
          }
        }
      }
    `
  });
};

export const getAdminUsers = async () => {
  return client.query({
    query: gql`
      ${USER_FRAGMENT}
      query {
        adminUsers {
          ...UserFragment
        }
      }
    `
  });
};

export const createAdminUser = async (
  userInput: UserInput,
  roleInput: RoleInput
) => {
  return client.mutate({
    mutation: gql`
      ${USER_FRAGMENT}
      mutation CreateAdminMutation($user: UserInput!, $role: RoleInput!) {
        createAdmin(user: $user, role: $role) {
          ...UserFragment
        }
      }
    `,
    variables: { user: userInput, role: roleInput }
  });
};

export const addUserRole = async (userId: number, roleInput: RoleInput) => {
  return client.mutate({
    mutation: gql`
      ${USER_FRAGMENT}
      mutation AddRoleMutation($userId: Int!, $roleInput: RoleInput!) {
        addRole(userId: $userId, input: $roleInput) {
          ...UserFragment
        }
      }
    `,
    variables: { userId, roleInput }
  });
};

export const removeUserRole = async (userId: number, roleInput: RoleInput) => {
  return client.mutate({
    mutation: gql`
      ${USER_FRAGMENT}
      mutation RemoveRoleMutation($userId: Int!, $roleInput: RoleInput!) {
        removeRole(userId: $userId, input: $roleInput) {
          ...UserFragment
        }
      }
    `,
    variables: { userId, roleInput }
  });
};

export const updateEmail = async (userId: number, newEmail: string) => {
  return client.mutate({
    mutation: gql`
      ${USER_FRAGMENT}
      mutation UpdateEmail($userId: Int!, $newEmail: String!) {
        updateEmail(userId: $userId, newEmail: $newEmail) {
          ...UserFragment
        }
      }
    `,
    variables: { userId, newEmail }
  });
};
