import Observable from "zen-observable";

/*
 Apollo link needs to return an observable and to refresh a token, need to call an async function
 that returns a promise. TODO: Apollo has a fromPromise fn but they are not exporting it properly yet.
*/
export const promiseToObservable = (promise: Promise<any>) =>
  new Observable((subscriber: any) => {
    promise.then(
      value => {
        if (subscriber.closed) return;
        subscriber.next(value);
        subscriber.complete();
      },
      err => subscriber.error(err)
    );
  });
