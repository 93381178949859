import React from "react";
import Spinner from "../Spinner";
import GeneralCard, { GeneralCardProps } from "./GeneralCard";
import { Grid } from "@material-ui/core";

interface GeneralCardListProps {
  cardDataList?: Array<GeneralCardProps> | any;
  subTitle: string;
  fetchingCards?: boolean;
}

const GeneralCardList: React.FC<GeneralCardListProps> = ({
  cardDataList,
  subTitle,
  fetchingCards
}) => {
  if (!cardDataList) {
    return null;
  }

  if (fetchingCards) {
    return <Spinner center />;
  }

  if (cardDataList.length < 1) {
    return (
      <p>
        <i>No businesses found with these filters.</i>
      </p>
    );
  }

  return (
    <Grid container spacing={3}>
      {cardDataList.map((data: GeneralCardProps, index: number) => (
        <GeneralCard key={index} {...data} subTitle={subTitle} />
      ))}
    </Grid>
  );
};

export default GeneralCardList;
