import React, { useEffect, useState } from "react";
import { SelectableImageTile } from "./SelectableImageTile";
import { makeStyles } from "@material-ui/core";
import { useField } from "formik";

interface Props {
  images: string[];
  name: string;
  onSelect: (selected: string | undefined) => void;
}

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "row"
  },
  errorText: {
    color: "#f44336",
    fontFamily: "Helvetica Neue",
    fontSize: "12px",
    paddingTop: "12px"
  }
});

// Used on deal form to select existing image already uploaded to location obj
export const SelectExistingImage = ({ images = [], name, onSelect }: Props) => {
  const classes = useStyles();
  const [selectedImage, setSelectedImage] = useState();
  const [errorText, setErrorText] = useState("");
  const [, meta] = useField(name);

  useEffect(() => {
    if (meta.error && meta.touched) {
      setErrorText("Must select at least 1 image");
    }
  }, [meta]);

  return (
    <>
      <div className={classes.container}>
        {images.map((img, index) => (
          <SelectableImageTile
            src={img}
            key={index}
            selected={img === selectedImage}
            onSelect={(src: string) => {
              onSelect(src === selectedImage ? undefined : src);
              setSelectedImage(src === selectedImage ? undefined : src);
            }}
          />
        ))}
      </div>
      {errorText && <div className={classes.errorText}>{errorText}</div>}
    </>
  );
};
