import React from "react";
import "./RightLandingBody.css";

const RightLandingBody: React.FC = () => {
  return (
    <div className="rightLandingBodyContainer">
      <div className="picContainer" />
    </div>
  );
};

export default RightLandingBody;
