import * as React from "react";
import { FieldAttributes, useField } from "formik";

import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";

const TextInput: React.FunctionComponent<FieldAttributes<any>> = ({
  label,
  ...props
}) => {
  const [field, meta] = useField(props);
  const hasError = meta.touched && !!meta.error;
  const outlined = props.multiline;

  return (
    <FormControl
      className={`textinput ${outlined ? "MuiOutlinedInput-FormControl" : ""}`}
      fullWidth={true}
      error={hasError}
    >
      <InputLabel>{label}</InputLabel>
      {outlined ? (
        <OutlinedInput id={props.name} error={hasError} {...field} {...props} />
      ) : (
        <Input id={props.name} error={hasError} {...field} {...props} />
      )}
      <FormHelperText error={hasError}>
        {hasError ? meta.error : null}
      </FormHelperText>
    </FormControl>
  );
};

export default TextInput;
