import React, { useState } from "react";

import Chevron from "../../../images/chevron.svg";
import CloseIcon from "../../../images/close-icon.svg";
import ForgotPasswordForm from "../../forms/forgot-password/ForgotPasswordForm";
import LoginForm from "../../forms/login/LoginForm";
import "./loginmodal.css";

import TransitionModal from "./TransitionModal";

type FormType = "LOGIN" | "FORGOT_PASSWORD";

interface LoginModalProps {
  open: boolean;

  handleClose(): void;
}

const LoginModal: React.FunctionComponent<LoginModalProps> = ({
  open,
  handleClose
}) => {
  const [formType, setFormType] = useState<FormType>("LOGIN");

  const goBack = () => {
    if (formType === "FORGOT_PASSWORD") {
      setFormType("LOGIN");
    } else {
      handleClose();
    }
  };

  return (
    <TransitionModal
      className="loginmodal"
      isOpen={open}
      handleClose={handleClose}
    >
      <div className="loginmodalinner">
        <div className="loginmodaltop">
          <div className="backcontainer" onClick={goBack}>
            <img src={Chevron} alt="Back icon" width={12} height={12} />
            <span className="back">Back</span>
          </div>
          <img
            className="closeicon"
            src={CloseIcon}
            alt="Close icon"
            onClick={goBack}
            width={12}
            height={12}
          />
        </div>
        <h1>{formType === "LOGIN" ? "Login" : "Forgot Password"}</h1>
        <div className="loginformcontainer">
          {formType === "LOGIN" ? (
            <LoginForm
              onClickForgotPassword={() => setFormType("FORGOT_PASSWORD")}
            />
          ) : (
            <ForgotPasswordForm />
          )}
        </div>
      </div>
    </TransitionModal>
  );
};

export default LoginModal;
