import React from "react";
import { PromoCodeInput } from "../../../types/offer";
import { Pill } from "../../common/Pill";
import { Card, IconButton, makeStyles } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

interface PromoCodeCardProps {
  promoCode: PromoCodeInput;
  onDelete: () => void;
}

const useStyles = makeStyles({
  card: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "2px 20px",
    margin: "10px 30px"
  }
});

export const PromoCodeCard = ({ promoCode, onDelete }: PromoCodeCardProps) => {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <p>
        {!!promoCode.redemptions?.length && <Pill color="gray">REDEEMED</Pill>}
        {promoCode.code}
      </p>
      <IconButton onClick={onDelete}>
        <DeleteIcon />
      </IconButton>
    </Card>
  );
};
