import React from "react";
import { NavLink } from "react-router-dom";

import "./CustomNavLink.css";

interface CustomNavLinkProps {
  to: string;
  text: string;
  exact?: boolean;
}

const CustomNavLink: React.FC<CustomNavLinkProps> = ({
  to,
  text,
  exact = false
}) => {
  return (
    <div className="navLinkContainer">
      <NavLink
        to={to}
        className="navLink"
        activeClassName="activeUrl"
        exact={exact}
      >
        {text}
      </NavLink>
    </div>
  );
};

export default CustomNavLink;
