import {
  Button,
  Dialog,
  DialogActions,
  DialogContent
} from "@material-ui/core";
import TextInput from "../forms/fields/textinput/TextInput";
import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { onSubmitFn } from "../forms/fields/FormikTypes";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

interface EditBusinessNameDialogProps {
  isOpen: boolean;
  onSubmit: onSubmitFn<EditBusinessNameFormValues>;
  onClose: () => void;
}

export interface EditBusinessNameFormValues {
  name: string;
}

export const EditBusinessNameDialog: React.FC<EditBusinessNameDialogProps> = ({
  isOpen,
  onSubmit,
  onClose
}) => {
  const business = useSelector((rootState: RootState) => rootState.business);

  return (
    <Dialog open={isOpen} maxWidth="xs" fullWidth>
      <DialogContent>
        <Formik
          initialValues={{ name: business.name }}
          onSubmit={onSubmit}
          validationSchema={Yup.object({
            name: Yup.string().required("Required")
          })}
        >
          <Form>
            <TextInput name="name" label="Name" />
            <DialogActions>
              <Button type="submit">Save</Button>
              <Button onClick={onClose}>Cancel</Button>
            </DialogActions>
          </Form>
        </Formik>
      </DialogContent>
    </Dialog>
  );
};
