import * as api from "../../../api";
import { FieldAttributes } from "formik";
import React, { useEffect } from "react";
import SelectField, { SelectItem } from "../fields/selectfield/SelectField";
import { Category } from "../../../types/generated-types";

const CategorySelect: React.FC<FieldAttributes<any>> = props => {
  const [selectOptions, setSelectOptions] = React.useState<SelectItem[]>([]);

  useEffect(() => {
    (async function() {
      const {
        data: { categories }
      } = await api.categories.getCategories();
      setSelectOptions(
        categories.map((cat: Category) => ({ title: cat.name, value: cat.id }))
      );
    })();
  }, []);
  return <SelectField {...props} selectOptions={selectOptions} />;
};

export default CategorySelect;
