import get from "lodash/get";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import * as api from "../../api";
import GeneralCardList from "../../components/common/body/GeneralCardList";
import { RootState, useMapDispatch } from "../../store";
import { Business } from "../../types/generated-types";
import "./AdminBusinessView.css";
import { FormControl, MenuItem } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import {
  getActiveBusinesses,
  getBusinessesBySearchQuery,
  getNoDealsBusinesses,
  getPendingBusinesses
} from "../../utils/filters";
import {
  BUSINESS_STATUS_FILTER_OPTIONS,
  BusinessStatus
} from "../../types/business";
import { useSelector } from "react-redux";
import OutlinedInput from "@material-ui/core/OutlinedInput";

const AdminBusinessView = () => {
  const history = useHistory();
  const [allBusinesses, setAllBusinesses] = useState<Business[]>([]);
  const [fetched, setFetched] = useState(false);
  const { setBusiness } = useMapDispatch(dispatch => ({
    setBusiness: dispatch.business.setBusiness
  }));
  const statusFilter = useSelector(
    (rootState: RootState) => rootState.business.statusFilter
  );
  const { setStatusFilter } = useMapDispatch(dispatch => ({
    setStatusFilter: dispatch.business.setStatusFilter
  }));
  const searchFilter = useSelector(
    (rootState: RootState) => rootState.business.searchFilter
  );
  const { setSearchFilter } = useMapDispatch(dispatch => ({
    setSearchFilter: dispatch.business.setSearchFilter
  }));

  const onStatusFilterChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const newValue = event.target.value;
    const filterOption = BUSINESS_STATUS_FILTER_OPTIONS.find(
      option => option.value === newValue
    );
    if (filterOption) setStatusFilter(filterOption);
  };

  const onSearchChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSearchFilter(event.target.value || "");
  };

  const fetchBusinesses = async () => {
    const {
      data: { businesses }
    } = await api.businesses.fetchBusinesses();
    setAllBusinesses(businesses);
    if (!fetched) setFetched(true);
  };

  useEffect(() => {
    if (allBusinesses.length === 0) {
      fetchBusinesses();
    }
  }, []);

  const displayBusinesses =
    useMemo(() => {
      let filtered = allBusinesses;

      // filter by status
      if (statusFilter.value === BusinessStatus.All) {
        filtered = allBusinesses;
      } else if (statusFilter.value === BusinessStatus.Active) {
        filtered = getActiveBusinesses(allBusinesses);
      } else if (statusFilter.value === BusinessStatus.Pending) {
        filtered = getPendingBusinesses(allBusinesses);
      } else if (statusFilter.value === BusinessStatus.NoDeals) {
        filtered = getNoDealsBusinesses(allBusinesses);
      }
      // filter by search
      if (searchFilter !== "") {
        filtered = getBusinessesBySearchQuery(filtered, searchFilter);
      }

      return filtered;
    }, [statusFilter, searchFilter, allBusinesses]) || [];

  const mapBusinessesToCards = () => {
    return displayBusinesses.map(business => {
      const imageUrl = get(
        business,
        ["locations", "0", "images", "0"],
        undefined
      );

      const cardClickAction = () => {
        setBusiness({ id: business.id, name: business.name });
        history.push(`/admin/locations/${business.id}`);
      };

      const numLocations = (business?.locations || []).filter(
        l => !l.archivedAt
      ).length;

      return {
        imageUrl,
        title: business.name,
        subTitleNumber: numLocations,
        cardClickAction
      };
    });
  };

  return (
    <div className="adminBusinessViewContainer">
      <div className="adminBusinessHeaderContainer">
        <div className="subHeaderBusinessesTitle">
          <p>Businesses</p>
          <div className="subHeaderBusinessesCount">
            {displayBusinesses.length}
          </div>
        </div>
        <div>
          <FormControl variant="outlined" className="adminBusinessSearch">
            <InputLabel>Search</InputLabel>
            <OutlinedInput
              value={searchFilter}
              type="search"
              label="Search"
              className="adminBusinessFilters"
              onChange={onSearchChange}
            />
          </FormControl>
          <FormControl variant="outlined">
            <InputLabel id="business-status-filter">Status</InputLabel>
            <Select
              labelId="business-status-filter"
              value={statusFilter.value}
              onChange={onStatusFilterChange}
              className="adminBusinessFilters"
              label="Status"
            >
              {BUSINESS_STATUS_FILTER_OPTIONS.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
      <GeneralCardList
        cardDataList={mapBusinessesToCards()}
        subTitle={"Locations"}
        fetchingCards={!fetched}
      />
    </div>
  );
};

export default AdminBusinessView;
