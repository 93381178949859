import * as React from "react";
import { FieldAttributes, useField } from "formik";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText/FormHelperText";
import { Checkbox } from "@material-ui/core";

export interface SelectItem {
  title: string;
  value?: string | number;
}

type SelectFieldProps = FieldAttributes<any> & {
  selectOptions: Array<SelectItem>;
  defaultOption?: SelectItem;
};

const SelectField: React.FC<SelectFieldProps> = ({
  label,
  selectOptions,
  defaultOption,
  multiple = false,
  ...props
}) => {
  const [field, meta] = useField(props);
  const hasError = !!meta.touched && !!meta.error;

  const renderCheckbox = (value: SelectItem["value"]) => (
    <Checkbox checked={(field.value || []).indexOf(value) > -1} />
  );

  const renderMenuItems = (items: SelectItem[]) =>
    items.map(item => (
      <MenuItem key={item.title} value={item.value || item.title}>
        {multiple ? renderCheckbox(item.value || item.title) : null}
        {item.title}
      </MenuItem>
    ));

  const renderMultipleSelections = (selected: Array<number | string>) =>
    selected
      .map(
        s =>
          selectOptions.find((o: SelectItem) => o.value === s || o.title === s)
            ?.title
      )
      .join(", ");

  return (
    <FormControl fullWidth={true} className="selectfield" error={hasError}>
      <InputLabel>{label}</InputLabel>
      <Select
        {...field}
        {...props}
        multiple={multiple}
        renderValue={multiple ? renderMultipleSelections : undefined} // passing undefined as a prop is the same as not including it
        MenuProps={{ getContentAnchorEl: () => null }} // fix bug with MUI v4 where multiselect menu jumps around page when clicked
      >
        {defaultOption && (
          <MenuItem key={defaultOption.title} value={defaultOption.value}>
            {multiple ? renderCheckbox(defaultOption.value) : null}
            {defaultOption.title}
          </MenuItem>
        )}
        {renderMenuItems(selectOptions)}
      </Select>
      <FormHelperText error={hasError}>
        {hasError ? meta.error : null}
      </FormHelperText>
    </FormControl>
  );
};

export default SelectField;
