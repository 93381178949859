import * as React from "react";

import AccountForm from "../../components/forms/account/AccountForm";

import "./account.css";

const Account: React.FunctionComponent = () => {
  return (
    <div className="container accountcontainer">
      <div className="accountinnercontainer">
        <h1>Account</h1>
        <div>
          <AccountForm />
        </div>
      </div>
    </div>
  );
};

export default Account;
