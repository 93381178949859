import {
  Button,
  FormControlLabel,
  FormLabel,
  makeStyles,
  Radio,
  RadioGroup
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Field, useFormikContext } from "formik";
import * as FormLayout from "../layout/FormLayout";
import { ToggleView } from "../layout/ToggleView";
import { OfferFormValues } from "../../../types/offer";
import { ImagesUploadRow } from "../image-upload/ImagesUploadRow";
import { SelectExistingImage } from "../image-upload/SelectExistingImage";
import { Location } from "../../../types/locations";

export const IMAGE_TYPES = {
  CHOOSE_FROM_LOCATION: "CHOOSE_FROM_LOCATION",
  UPLOAD_NEW: "UPLOAD_NEW"
};

interface DealImageFormProps {
  editInputs?: OfferFormValues;
  locations: Location[];
}

const IMAGES_FIELD_NAME = "images";

const useStyles = makeStyles({
  imagePreview: {
    height: 100
  },
  container: {
    marginBottom: 100
  },
  radioGroup: {
    marginBottom: 24
  }
});

export const DealImageForm = ({
  editInputs,
  locations
}: DealImageFormProps) => {
  const classes = useStyles();
  const [imageType, setImageType] = useState(IMAGE_TYPES.CHOOSE_FROM_LOCATION);
  const [editModeOn, setEditModeOn] = useState(false);
  const [existingImage, setExistingImage] = useState();
  const { setFieldValue } = useFormikContext<OfferFormValues>();

  // reset fields when toggling between different deals
  useEffect(() => {
    setImageType(IMAGE_TYPES.CHOOSE_FROM_LOCATION);
    setFieldValue("imageType", IMAGE_TYPES.CHOOSE_FROM_LOCATION);
    setExistingImage(editInputs?.images?.[0]);
    setEditModeOn(false);
  }, [editInputs]);

  const onSelectImage = (selected: string | undefined) => {
    setFieldValue(IMAGES_FIELD_NAME, selected ? [selected] : []);
  };

  const onEdit = () => {
    onSelectImage(undefined);
    setExistingImage(undefined);
    setEditModeOn(true);
    setImageType(IMAGE_TYPES.CHOOSE_FROM_LOCATION);
    setFieldValue("imageType", IMAGE_TYPES.CHOOSE_FROM_LOCATION);
  };

  const showNoImageMessage = !existingImage && editInputs && !editModeOn;

  if (existingImage) {
    return (
      <div className={classes.container}>
        <FormLabel>Selected image</FormLabel>
        <FormLayout.Column>
          <img src={existingImage} alt="" className={classes.imagePreview} />
        </FormLayout.Column>
        <Button color="secondary" onClick={onEdit}>
          Edit
        </Button>
      </div>
    );
  }

  if (showNoImageMessage) {
    return (
      <div className={classes.container}>
        <FormLayout.Column>
          <p>No image selected or uploaded</p>
        </FormLayout.Column>
        <Button color="secondary" onClick={onEdit}>
          Edit
        </Button>
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <FormLabel>
        How would you like to choose an image for this deal?
      </FormLabel>
      <RadioGroup
        value={imageType}
        className={classes.radioGroup}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setImageType((event.target as HTMLInputElement).value);
        }}
      >
        <Field
          type="radio"
          name="imageType"
          as={FormControlLabel}
          control={<Radio />}
          value={IMAGE_TYPES.CHOOSE_FROM_LOCATION}
          label="Choose an existing image"
        />
        <Field
          type="radio"
          name="imageType"
          as={FormControlLabel}
          control={<Radio />}
          value={IMAGE_TYPES.UPLOAD_NEW}
          label="Upload a new photo"
        />
      </RadioGroup>
      <ToggleView value={imageType} index={IMAGE_TYPES.CHOOSE_FROM_LOCATION}>
        <SelectExistingImage
          images={locations?.[0]?.images || []}
          onSelect={onSelectImage}
          name={IMAGES_FIELD_NAME}
        />
      </ToggleView>
      <ToggleView value={imageType} index={IMAGE_TYPES.UPLOAD_NEW}>
        <ImagesUploadRow name={IMAGES_FIELD_NAME} multiple={false} />
      </ToggleView>
    </div>
  );
};
