import * as React from "react";

import SignupForm from "../components/forms/signup/signupform/SignupForm";
import LeftLandingBody from "../components/landing/LeftLandingBody";

import "./signup.css";

const Signup: React.FunctionComponent = () => {
  const title = "Drive More Traffic to Your Business at No Cost to You";

  return (
    <div className="signupcontainer">
      <div className="left">
        <LeftLandingBody
          title={title}
          subtitle={
            <ul>
              <li>Step 1: Register Your Business</li>
              <li>Step 2: Create Your Profile</li>
              <li>Step 3: Create Deals</li>
              <li>Step 4: Gain New Customers </li>
            </ul>
          }
          titleHighlightColor="#08D9D6"
        />
      </div>
      <div className="right signupformcontainer">
        <SignupForm />
      </div>
    </div>
  );
};

export default Signup;
