import React from "react";

import "./AddLocationPageIndicator.css";

interface AddLocationPageIndicatorProps {
  activeIndex: number;
  numOfLines: number;
}

const ActiveLine: React.FC = () => {
  return <div className="addLocationPageIndicatorActive"> </div>;
};

const InActiveLine: React.FC = () => {
  return (
    <div className="addLocationPageIndicatorActive addLocationPageIndicatorInActive"></div>
  );
};

const Spacing: React.FC = () => {
  return <span style={{ width: "20px" }}></span>;
};

const indicatorBuilder = (activeIndex: number, numOfIndexs: number) => {
  const toReturn: Array<JSX.Element> = [];
  for (let i = 0; i < numOfIndexs; ++i) {
    if (i === activeIndex) toReturn.push(<ActiveLine key={`active_${i}`} />);
    else toReturn.push(<InActiveLine key={`inactive${i}`} />);
    if (i + 1 !== numOfIndexs) toReturn.push(<Spacing key={`space_${i}`} />);
  }
  return toReturn;
};

const AddLocationPageIndicator: React.FC<AddLocationPageIndicatorProps> = ({
  activeIndex,
  numOfLines
}) => {
  return (
    <div className="addLocationPageIndicatorContainer">
      {indicatorBuilder(activeIndex, numOfLines)}
    </div>
  );
};

export default AddLocationPageIndicator;
