import { createModel, ModelConfig } from "@rematch/core";
import {
  BUSINESS_STATUS_FILTER_OPTIONS,
  BusinessStatusFilterOption
} from "../types/business";

interface DatabaseBusiness {
  id: number;
  name: string;
}

interface BusinessState extends DatabaseBusiness {
  error: any;
  statusFilter: BusinessStatusFilterOption;
  searchFilter: string;
}

export const business: ModelConfig<BusinessState> = createModel<BusinessState>({
  state: {
    id: "",
    name: "",
    error: null,
    statusFilter: BUSINESS_STATUS_FILTER_OPTIONS[0],
    searchFilter: ""
  },
  reducers: {
    setBusiness: (state: BusinessState, business: DatabaseBusiness) => {
      return {
        ...state,
        ...business,
        error: null
      };
    },
    setError: (state: BusinessState, error: any) => ({
      ...state,
      error
    }),
    setStatusFilter: (
      state: BusinessState,
      statusFilter: BusinessStatusFilterOption
    ) => ({
      ...state,
      statusFilter
    }),
    setSearchFilter: (state: BusinessState, searchFilter: string) => ({
      ...state,
      searchFilter
    })
  }
});
