import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import findIndex from "lodash/findIndex";
import get from "lodash/get";
import { useSelector } from "react-redux";
import * as api from "../../api";

import AddLocationsFormLocationList from "../../components/locations/addLocation/AddLocationsFormLocationList";
import { RootState } from "../../store";
import { AddLocationFormValues, Location } from "../../types/locations";
import { cloneWithout } from "../../utils/cloneWithout";
import { reduce } from "lodash";
import { HoursObj } from "../../types/generated-types";
import { onSubmitFn } from "../../components/forms/fields/FormikTypes";
import LocationFormTemplate from "../../components/forms/location/LocationFormTemplate";

const BLANK_TIMES = {
  monday: { open: "", close: "", isClosed: false },
  tuesday: { open: "", close: "", isClosed: false },
  wednesday: { open: "", close: "", isClosed: false },
  thursday: { open: "", close: "", isClosed: false },
  friday: { open: "", close: "", isClosed: false },
  saturday: { open: "", close: "", isClosed: false },
  sunday: { open: "", close: "", isClosed: false },
  mondays: [{ open: "", close: "" }],
  tuesdays: [{ open: "", close: "" }],
  wednesdays: [{ open: "", close: "" }],
  thursdays: [{ open: "", close: "" }],
  fridays: [{ open: "", close: "" }],
  saturdays: [{ open: "", close: "" }],
  sundays: [{ open: "", close: "" }]
};

const LocationFormPage: React.FC = () => {
  const [fetchedLocations, setFetchedLocations] = useState<Location[]>([]);
  const { businessId = "", locationId = "" } = useParams<{
    businessId: string;
    locationId: string;
  }>();
  const history = useHistory();
  const [locationIndex, setLocationIndex] = useState<number>(-1);
  const business = useSelector((rootState: RootState) => rootState.business);
  const hasNonMerchantPrivileges = useSelector(
    (rootState: RootState) => rootState.user.privileges.hasNonMerchantPrivileges
  );

  const fetchLocations = async () => {
    const queryId = hasNonMerchantPrivileges
      ? parseInt(businessId, 10)
      : business.id;
    try {
      const {
        data: { locations }
      } = await api.locations.getLocations(queryId);
      setFetchedLocations(
        locations?.map((loc: Location) =>
          cloneWithout("__typename", loc)
        ) as Location[]
      );
      setLocationIndex(
        findIndex(locations, (l: Location) => l.id.toString() === locationId)
      );
    } catch (error) {
      console.log(`Error fetching locations for business id ${queryId}`, error);
    }
  };

  useEffect(() => {
    if (businessId) {
      fetchLocations();
    }
  }, [businessId]);

  const isLocationIndexSet = locationIndex !== -1;

  const onSubmitFunction = async (values: AddLocationFormValues) => {
    const locationInput = { businessId: parseInt(businessId, 10), ...values };

    if (!isLocationIndexSet) {
      try {
        const {
          data: { createLocation }
        } = await api.locations.addLocation(locationInput);
        const newLocations = [...fetchedLocations, createLocation];
        setFetchedLocations(
          newLocations.map(loc => cloneWithout("__typename", loc)) as Location[]
        );
      } catch (error) {
        console.log(
          `Error adding location for business id ${businessId}`,
          error
        );
      }
    } else {
      try {
        const {
          data: { updateLocation }
        } = await api.locations.updateLocation(
          fetchedLocations[locationIndex].id,
          locationInput
        );
        const newLocations = fetchedLocations.map(l =>
          l.id === updateLocation.id ? updateLocation : l
        );
        setFetchedLocations(
          newLocations.map(loc => cloneWithout("__typename", loc)) as Location[]
        );
        setLocationIndex(-1);
      } catch (error) {
        console.log(`Error updating location`, error);
      }
    }
  };

  const onLocationClick = (locationIndex: number) => {
    setLocationIndex(locationIndex);
  };

  const removeLocation = (locationId: number) => {
    api.locations.archiveLocation(locationId);
    const newLocations = fetchedLocations.filter(l => l.id !== locationId);
    setFetchedLocations(
      newLocations.map(loc => cloneWithout("__typename", loc)) as Location[]
    );
  };

  const onButtonClick = () => {
    if (hasNonMerchantPrivileges || isLocationIndexSet) {
      history.goBack();
    } else {
      history.push("/adddeal");
    }
  };

  const initialFormValues: AddLocationFormValues = {
    address: isLocationIndexSet ? fetchedLocations[locationIndex].address : "",
    city: isLocationIndexSet ? fetchedLocations[locationIndex].city : "",
    state: isLocationIndexSet ? fetchedLocations[locationIndex].state : "",
    zipcode: isLocationIndexSet ? fetchedLocations[locationIndex].zipcode : "",
    email: isLocationIndexSet ? fetchedLocations[locationIndex].email : "",
    phone: isLocationIndexSet ? fetchedLocations[locationIndex].phone : "",
    images: isLocationIndexSet ? fetchedLocations[locationIndex].images : [],
    website: isLocationIndexSet ? fetchedLocations[locationIndex].website : "",
    tagline: isLocationIndexSet ? fetchedLocations[locationIndex].tagline : "",
    name: isLocationIndexSet
      ? fetchedLocations[locationIndex].name || business.name
      : business.name,
    hours: isLocationIndexSet
      ? reduce(
          BLANK_TIMES,
          (acc, val, key) => {
            const savedData = (fetchedLocations[locationIndex].hours as Record<
              string,
              HoursObj | HoursObj[] | null
            >)[key];
            if (savedData) {
              acc[key] = savedData;
            } else {
              acc[key] = val;
            }
            return acc;
          },
          {} as any
        )
      : BLANK_TIMES,
    categories: isLocationIndexSet
      ? fetchedLocations[locationIndex].categories?.map(cat => cat.id) || []
      : []
  };

  const businessName = hasNonMerchantPrivileges
    ? get(fetchLocations, [locationIndex, "business", "name"], "")
    : business.name;

  const onSubmit: onSubmitFn<AddLocationFormValues> = async (
    values,
    { setSubmitting, resetForm }
  ) => {
    setSubmitting(false);
    onSubmitFunction(values);
    resetForm({ values: initialFormValues });
  };

  return (
    <LocationFormTemplate
      name={businessName}
      title={isLocationIndexSet ? "Edit Location" : "Add Location"}
      activeIndex={0}
      numOfLines={isLocationIndexSet ? 0 : 2}
      initialFormValues={initialFormValues}
      onButtonClick={onButtonClick}
      buttonText={isLocationIndexSet ? "Done" : "Next"}
      onSubmit={onSubmit}
      submitButtonText={isLocationIndexSet ? "Save Changes" : "Add Location"}
      sideList={
        <AddLocationsFormLocationList
          locations={fetchedLocations}
          onLocationClick={onLocationClick}
          removeLocation={removeLocation}
          isFetching={!fetchedLocations}
        />
      }
    />
  );
};

export default LocationFormPage;
