import React from "react";
import {
  Card,
  CardActionArea,
  makeStyles,
  CardContent,
  CardMedia
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

import "./LocationCard.css";
import { Location } from "../../types/locations";

export interface LocationCardProps {
  location: Location;
}

const useStyles = makeStyles({
  card: {
    width: "300px",
    height: "304px",
    marginRight: "30px",
    marginBottom: "30px"
  },
  media: {
    width: "300px",
    height: "208px"
  },
  title: {
    fontWeight: "bold",
    fontSize: "20px",
    margin: 0,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  subtitle: {
    fontSize: "14px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  }
});

const LocationCard: React.FC<LocationCardProps> = ({ location }) => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardActionArea onClick={() => history.push(`/location/${location.id}`)}>
        <CardMedia className={classes.media} image={location.images[0]} />
        <CardContent>
          {location.name === location.business?.name || !location.name ? (
            <p className={classes.title}>{location.address}</p>
          ) : (
            <>
              <p className={classes.title}>{location.name}</p>
              <p className={classes.subtitle}>{location.address}</p>
            </>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default LocationCard;
