import React, { useEffect, useState } from "react";

import TextInput from "../fields/textinput/TextInput";
import * as FormLayout from "../layout/FormLayout";
import SelectField from "../fields/selectfield/SelectField";
import * as api from "../../../api";

import { OfferFormValues } from "../../../types/offer";
import { Location } from "../../../types/locations";
import { OfferType } from "../../../types/generated-types";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import CategorySelect from "../category/CategorySelect";
import { TAB_INDEX } from "./DealFormTemplate";
import { DealImageForm } from "./DealImageForm";
import { InputAdornment } from "@material-ui/core";
import { useField } from "formik";

const DEAL_TYPES = [
  { title: "Dollars off", value: OfferType.DollarsOff },
  { title: "Buy one, get one", value: OfferType.Bogo },
  { title: "Percentage off", value: OfferType.Percent },
  { title: "Free item", value: OfferType.FreeItem },
  { title: "Free entry", value: OfferType.FreeEntry }
];
const REDEMPTION_TYPES = [
  { value: "ONE_TIME", title: "Once per year" },
  { value: "CONTINUOUS", title: "Daily" }
];
const REDEEMABILITY_TYPES = [{ title: "FREE" }, { title: "PREMIUM" }];

interface DealFormProps {
  locationId?: number;
  editInputs?: OfferFormValues;
  tab: number;
}

export const DealForm: React.FC<DealFormProps> = ({
  locationId,
  editInputs,
  tab
}) => {
  const hasNonMerchantPrivileges = useSelector(
    (rootState: RootState) => rootState.user.privileges.hasNonMerchantPrivileges
  );
  const [locationOptions, setLocationOptions] = useState<Location[]>([]);
  const business = useSelector((rootState: RootState) => rootState.business);
  const [valueField, , valueFieldHelpers] = useField("value");

  useEffect(() => {
    (async () => {
      try {
        const {
          data: { locations }
        } = await api.locations.getLocations(business.id);
        setLocationOptions(locations);
      } catch (error) {
        console.log(`Error fetching locations options for new offer`, error);
      }
    })();
  }, [business]);

  return (
    <>
      <FormLayout.Column>
        <CategorySelect
          name="categories"
          label={
            hasNonMerchantPrivileges ? "Categories" : "Categories (Choose 1)"
          }
          multiple
        />
      </FormLayout.Column>
      <FormLayout.Column>
        <SelectField
          name="type"
          label="Type of Deal"
          selectOptions={DEAL_TYPES}
        />
      </FormLayout.Column>
      <FormLayout.Column>
        <TextInput name="title" label="Title" />
      </FormLayout.Column>
      <FormLayout.Column>
        <TextInput
          name="description"
          label="Description (optional)"
          multiline
          rows={4}
        />
      </FormLayout.Column>
      <FormLayout.Column>
        <TextInput name="disclaimer" label="Disclaimer (optional)" />
      </FormLayout.Column>
      <FormLayout.Row>
        {/*  TODO: Sarah - should make redemptionType optional on backend so we don't have to pass a value when creating promos */}
        <FormLayout.Column hidden={tab === TAB_INDEX.PROMO}>
          <SelectField
            name="redemptionType"
            label="Can be redeemed"
            selectOptions={REDEMPTION_TYPES}
          />
        </FormLayout.Column>
        <FormLayout.Column>
          <TextInput
            name="expiration"
            label="Expiration"
            placeholder="mm-dd-yyyy"
          />
        </FormLayout.Column>
        {hasNonMerchantPrivileges ? (
          <FormLayout.Column>
            <SelectField
              name="redeemability"
              label="Redeemability"
              selectOptions={REDEEMABILITY_TYPES}
            />
          </FormLayout.Column>
        ) : null}
      </FormLayout.Row>
      <FormLayout.Column>
        <TextInput
          name="value"
          label="Estimated Savings"
          type="number"
          value={valueField.value}
          onBlur={(e: any) => {
            const newValue = isNaN(parseFloat(e.target.value))
              ? ""
              : parseFloat(e.target.value).toFixed(2); // 2 decimal places
            valueFieldHelpers.setValue(newValue);
          }}
          inputProps={{ min: 0.01, step: "any" }}
          startAdornment={
            <InputAdornment position="start" style={{ marginBottom: 10 }}>
              $
            </InputAdornment>
          }
        />
      </FormLayout.Column>
      <h2>Image</h2>
      <DealImageForm editInputs={editInputs} locations={locationOptions} />
      <div style={locationId || editInputs ? { display: "none" } : {}}>
        <FormLayout.Column>
          <SelectField
            name="location"
            label="Location"
            selectOptions={locationOptions.map(l => ({
              title: `${l.city}, ${l.state}`,
              value: l.id
            }))}
          />
        </FormLayout.Column>
      </div>
    </>
  );
};
