import { init, RematchRootState } from "@rematch/core";
import createRematchPersist, { getPersistor } from "@rematch/persist";
import createLoadingPlugin from "@rematch/loading";

import { useDispatch } from "react-redux";

import * as models from "./models";

const loadingPlugin = createLoadingPlugin();
const persistPlugin = createRematchPersist({
  whitelist: ["user", "business"],
  version: 1
});

const store = init({
  redux: {
    rootReducers: {
      "auth/signOut": () => undefined
    }
  },
  plugins: [loadingPlugin, persistPlugin],
  models
});

export default store;

export const persistor = getPersistor();

// Loading plugin typescript fix
// https://github.com/rematch/rematch/issues/562#issuecomment-457496194
interface LoadingState {
  loading: {
    global: boolean;
    models: RematchRootState<typeof models>;
    effects: Dispatch;
  };
}

export type Store = typeof store;
export type Dispatch = typeof store.dispatch;
export type RootState = RematchRootState<typeof models> & LoadingState;

export const useMapDispatch = <MD>(selector: (dispatch: Dispatch) => MD) => {
  const dispatch = useDispatch<Dispatch>();
  return selector(dispatch);
};
