import React from "react";

import TextInput from "../fields/textinput/TextInput";
import * as FormLayout from "../layout/FormLayout";
import StartToEndTime from "./StartToEndTime";
import { ImagesUploadRow } from "../image-upload/ImagesUploadRow";

import "./LocationForm.css";
import CategorySelect from "../category/CategorySelect";
import { AddLocationFormValues } from "../../../types/locations";
import AddressInput from "../fields/address/AddressInput";

interface LocationFormProps {
  initialValues: AddLocationFormValues;
}
const LocationForm: React.FC<LocationFormProps> = ({ initialValues }) => {
  return (
    <>
      <FormLayout.Column>
        <TextInput
          name="name"
          label="Location Name (if different than business name)"
        />
      </FormLayout.Column>
      <FormLayout.Column>
        <CategorySelect
          name="categories"
          label="Categories (Choose up to 3)*"
          multiple
        />
      </FormLayout.Column>
      <FormLayout.Column>
        <AddressInput initialValues={initialValues} />
      </FormLayout.Column>
      <FormLayout.Row>
        <FormLayout.Column>
          <TextInput name="phone" label="Location Phone Number" />
        </FormLayout.Column>
        <FormLayout.Column>
          <TextInput name="email" label="Location Email" />
        </FormLayout.Column>
      </FormLayout.Row>
      <FormLayout.Column>
        <TextInput name="website" label="Website" />
      </FormLayout.Column>
      <FormLayout.Column>
        <TextInput
          name="tagline"
          label="Customer Experience Tagline (ie: Live music daily with an ocean view rooftop)"
        />
      </FormLayout.Column>
      <h1>Images</h1>
      <p>
        First image is the display image; click and drag to rearrange. The
        display image should have centered content or have a vertical
        orientation to work best for full screen content
      </p>
      <FormLayout.Column>
        <ImagesUploadRow name="images" />
      </FormLayout.Column>
      <h1>Hours of Operation</h1>
      <div className="hoursGrid">
        <StartToEndTime label="Monday" inputName="hours.monday" />
        <StartToEndTime label="Tuesday" inputName="hours.tuesday" />
        <StartToEndTime label="Wednesday" inputName="hours.wednesday" />
        <StartToEndTime label="Thursday" inputName="hours.thursday" />
        <StartToEndTime label="Friday" inputName="hours.friday" />
        <StartToEndTime label="Saturday" inputName="hours.saturday" />
        <StartToEndTime label="Sunday" inputName="hours.sunday" />
      </div>
    </>
  );
};

export default LocationForm;
