import { createModel, ModelConfig } from "@rematch/core";
import Firebase from "firebase/app";
import get from "lodash/get";
import pick from "lodash/pick";
import * as api from "../api";

import { persistor } from "../store";
import { Role, User, UserRole } from "../types/generated-types";
import { AccountFormValues, SignupFormValues } from "../types/user";

enum UserPrivileges {
  NonMerchant,
  Delete,
  DeleteBusiness,
  Approve,
  ManageUsers
}

interface Privileges {
  hasNonMerchantPrivileges: boolean;
  hasDeletePrivileges: boolean;
  hasDeleteBusinessPrivileges: boolean;
  hasApprovePrivileges: boolean;
  hasManageUsersPrivileges: boolean;
}

const USER_ROLES_TO_PRIVILEGES = {
  [UserPrivileges.NonMerchant]: [Role.Admin, Role.SalesManager, Role.SalesRep],
  [UserPrivileges.Delete]: [Role.Admin, Role.Merchant],
  [UserPrivileges.DeleteBusiness]: [Role.Admin],
  [UserPrivileges.Approve]: [Role.Admin, Role.SalesManager],
  [UserPrivileges.ManageUsers]: [Role.Admin]
};

const rolesToPrivileges = (roles: UserRole[]): Privileges => {
  return {
    hasNonMerchantPrivileges:
      roles.filter(({ role }) =>
        USER_ROLES_TO_PRIVILEGES[UserPrivileges.NonMerchant].includes(role)
      ).length > 0,
    hasApprovePrivileges:
      roles.filter(({ role }) =>
        USER_ROLES_TO_PRIVILEGES[UserPrivileges.Approve].includes(role)
      ).length > 0,
    hasDeletePrivileges:
      roles.filter(({ role }) =>
        USER_ROLES_TO_PRIVILEGES[UserPrivileges.Delete].includes(role)
      ).length > 0,
    hasDeleteBusinessPrivileges:
      roles.filter(({ role }) =>
        USER_ROLES_TO_PRIVILEGES[UserPrivileges.DeleteBusiness].includes(role)
      ).length > 0,
    hasManageUsersPrivileges:
      roles.filter(({ role }) =>
        USER_ROLES_TO_PRIVILEGES[UserPrivileges.ManageUsers].includes(role)
      ).length > 0
  };
};

interface DatabaseUser {
  privileges: Privileges;
  id: number;
  firstName: string;
  lastName: string;
  email: string;
}

interface UserState extends DatabaseUser {
  idToken: string;
  emailVerified: boolean;
  error: any;
}

interface LoginInfo {
  email: string;
  password: string;
}

const initialState = {
  idToken: "",
  privileges: {
    hasNonMerchantPrivileges: false,
    hasDeletePrivileges: false,
    hasDeleteBusinessPrivileges: false,
    hasApprovePrivileges: false,
    hasManageUsersPrivileges: false
  },
  id: null,
  firstName: "",
  lastName: "",
  email: "",
  emailVerified: false,
  error: null
};

export const user: ModelConfig<UserState> = createModel<UserState>({
  state: initialState,

  reducers: {
    setIdTokenState: (state, idToken: string) => ({
      ...state,
      idToken,
      error: null
    }),
    setUserState: (state, dbUser: DatabaseUser) => ({
      ...state,
      ...dbUser,
      error: null
    }),
    setUserEmailVerified: (state, emailVerified: boolean) => ({
      ...state,
      emailVerified,
      error: null
    }),
    setError: (state, error: any) => ({
      ...state,
      error
    })
  },

  effects: dispatch => ({
    async login({ email, password }: LoginInfo) {
      await Firebase.auth().signInWithEmailAndPassword(email, password);
      await this.getIdToken();
      return this.getUserByExtId();
    },

    async signUp(values: SignupFormValues) {
      try {
        await Firebase.auth().createUserWithEmailAndPassword(
          values.email,
          values.password
        );
        await this.getIdToken();
        await Firebase.auth().currentUser?.sendEmailVerification();

        const {
          data: { createMerchant }
        } = await api.auth.createMerchant(values);
        this.setUserFromDatabase(createMerchant);
      } catch (error) {
        this.setError(error);
      }
    },

    async getIdToken() {
      const forceRefresh = true;
      const idToken = await Firebase.auth().currentUser?.getIdToken(
        forceRefresh
      );
      this.setIdTokenState(idToken);
      return idToken;
    },

    async getUserByExtId() {
      try {
        const {
          data: { userByExtId }
        } = await api.auth.getUserByExtId();
        return this.setUserFromDatabase(userByExtId);
      } catch (err) {
        this.setError(err);
      }
    },

    async updateUser({
      id,
      values
    }: {
      id: number;
      values: AccountFormValues;
    }) {
      try {
        const {
          data: { updateUser }
        } = await api.auth.updateUser(id, values);
        this.setUserFromDatabase(updateUser);
      } catch (err) {
        this.setError(err);
      }
    },

    setUserInfoFromFirebase(user: firebase.User) {
      this.setUserEmailVerified(user.emailVerified);
    },

    setUserFromDatabase(user: User) {
      const dbUser = {
        ...pick(user, ["id", "firstName", "lastName", "email"]),
        privileges: rolesToPrivileges(user.roles)
      };
      this.setUserState(dbUser);
      const business = get(user, "business", {});
      dispatch.business.setBusiness(business);
      return dbUser;
    },

    async signOut() {
      await Firebase.auth().signOut();
      await persistor.purge();
      this.setUserState(initialState);
      dispatch.business.setBusiness({ id: "", name: "" });
    }
  })
});
