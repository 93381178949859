import React from "react";
import {
  Button,
  Card,
  CardActionArea,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles
} from "@material-ui/core";

import PrimaryButton from "../forms/buttons/PrimaryButton";
import { Offer } from "../../types/offer";
import { Pill } from "../common/Pill";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const useStyles = makeStyles({
  card: selected => ({
    display: "flex",
    margin: "20px 0",
    ...(selected && { backgroundColor: "#E0E0E0" })
  }),
  actionArea: {
    height: "100%",
    paddingLeft: "16px"
  }
});

interface SimpleDealCardProps {
  offer: Offer;
  remove: () => void;
  onClick: () => void;
  selected: boolean;
  showRedemptions: boolean;
}

const SimpleDealCard: React.FC<SimpleDealCardProps> = ({
  offer,
  remove,
  onClick,
  selected,
  showRedemptions
}) => {
  const [removeDealDialog, setRemoveDealDialog] = React.useState(false);
  const classes = useStyles(selected);
  const hasDeletePrivileges = useSelector(
    (state: RootState) => state.user.privileges.hasDeletePrivileges
  );
  const onDialogClose = () => {
    setRemoveDealDialog(false);
  };

  const onRemoveClick = () => {
    setRemoveDealDialog(true);
  };

  const removeOffer = () => {
    remove();
    setRemoveDealDialog(false);
  };

  return (
    <Card className={classes.card}>
      <div className="simpleLocationCardContainer">
        <CardActionArea onClick={onClick} className={classes.actionArea}>
          <div>
            <p>
              {!!offer.promo && <Pill color="pink">PROMO</Pill>}
              <span className="simpleLocationCardText">
                {offer.title || ""}
              </span>
            </p>
            {showRedemptions && (
              <p className="simpleLocationCardRedemptionsText">
                {offer.totalRedemptionsCount} Redemption
                {offer.totalRedemptionsCount !== 1 && "s"}
              </p>
            )}
          </div>
        </CardActionArea>
        {hasDeletePrivileges && (
          <div className="simpleLocationCardRemoveContainer">
            <PrimaryButton btnType={"inverted"} onClick={onRemoveClick}>
              Remove
            </PrimaryButton>
          </div>
        )}
      </div>
      <Dialog open={removeDealDialog} onClose={onDialogClose}>
        <DialogContent>
          <DialogTitle id="alert-dialog-title">
            Are you sure you want to remove this Deal?
          </DialogTitle>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={removeOffer}>
            Yes
          </Button>
          <Button color="primary" onClick={onDialogClose}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default SimpleDealCard;
