import React, { useState, useEffect } from "react";
import * as firebase from "firebase/app";
import "firebase/storage";
import { makeStyles } from "@material-ui/core";
import last from "lodash/last";
import round from "lodash/round";
import { useSelector } from "react-redux";

import { RootState } from "../../../store";

const useStyles = makeStyles({
  imageTile: {
    position: "relative",
    height: 64,
    width: 64,
    marginRight: 24,
    borderRadius: "4px"
  },
  image: {
    height: 64,
    width: 64,
    objectFit: "cover",
    borderRadius: "4px"
  },
  closeButton: {
    position: "absolute",
    top: -8,
    right: -8
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    height: 64,
    width: 64,
    marginRight: 24,
    borderRadius: "4px",
    backgroundColor: "#ff4a76"
  }
});

interface Props {
  image: File;
  removeImageFile: () => void;
  setUrls: React.Dispatch<React.SetStateAction<string[]>>;
}

export const ImageTile = ({ image, removeImageFile, setUrls }: Props) => {
  const [overlayOpacity, setOverlayOpacity] = useState<number>(0.7);
  const [uploadTask, setUploadTask] = useState<firebase.storage.UploadTask>();
  const businessName = useSelector(
    (rootState: RootState) => rootState.business.name
  );

  const classes = useStyles();
  const storageRef = firebase.storage().ref();

  const extension = last(image?.name.split("."))?.toLowerCase() || "";

  const uploadToFirebase = () => {
    // Replaces all special characters and white space with underscore
    const formattedBusinessName = businessName
      ? businessName.toLowerCase().replace(/[^A-Z0-9]+/gi, "_")
      : "_";
    const timeStamp = Date.now();
    const refPath = `public/images/${formattedBusinessName}_${image.name
      .split(".")[0]
      .toLowerCase()}_${timeStamp}.${extension}`;
    const uploadTask = storageRef.child(refPath).put(image);
    setUploadTask(uploadTask);
  };

  useEffect(() => {
    uploadToFirebase();
  }, []);

  // tracking
  useEffect(() => {
    if (uploadTask && uploadTask.snapshot.state !== "success") {
      const success = async () => {
        const downloadUrl = await uploadTask.snapshot.ref.getDownloadURL();
        setUrls(prev => [...prev, downloadUrl]);
        removeImageFile();
      };

      const failure = () => {
        // Upload failed, remove image file from component
        removeImageFile();
      };

      const trackProgress = (snapshot: firebase.storage.UploadTaskSnapshot) => {
        const { bytesTransferred, totalBytes } = snapshot;
        const percentageComplete = round(bytesTransferred / totalBytes, 2);
        const opacity = 1 - percentageComplete;
        const newOpacity = opacity > 0.7 ? 0.7 : opacity;
        setOverlayOpacity(newOpacity);
      };

      const unsub = uploadTask.on(
        "state_changed",
        trackProgress,
        failure,
        success
      );
      return () => {
        unsub();
      };
    }
  }, [uploadTask]);

  const imageSource = URL.createObjectURL(image);
  if (!imageSource) {
    return null;
  }

  return (
    <div className={classes.imageTile}>
      <img className={classes.image} src={imageSource} alt={"Thumbnail"} />
      <div className={classes.overlay} style={{ opacity: overlayOpacity }} />
    </div>
  );
};
