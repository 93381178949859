import { client } from "../services/apollo";
import { gql } from "apollo-boost";
import { OfferInput } from "../types/offer";

const offerFragment = gql`
  fragment OfferResponse on Offer {
    id
    validFrom
    validTo
    title
    description
    disclaimer
    type
    redemptionType
    redeemability
    totalRedemptionsCount
    status
    value
    business {
      id
      name
    }
    locations {
      id
    }
    categories {
      id
      name
    }
    image
    promo {
      promoType
      redeemUrl
      promoCodes {
        id
        code
        createdAt
        redemptions {
          id
        }
      }
    }
  }
`;

export const fetchOffers = ({
  locationId,
  businessId,
  status
}: {
  locationId?: number;
  businessId?: number;
  status?: string;
}) => {
  return client.query({
    query: gql`
      query GetOffers($locationId: Int, $businessId: Int, $status: Status) {
        offers(
          locationId: $locationId
          businessId: $businessId
          status: $status
        ) {
          ...OfferResponse
        }
      }
      ${offerFragment}
    `,
    variables: { locationId, businessId, status }
  });
};

export const approveOffer = (id: number) =>
  client.mutate({
    mutation: approveOfferMutation,
    variables: {
      id
    }
  });

export const denyOffer = (id: number) =>
  client.mutate({
    mutation: denyOfferMutation,
    variables: {
      id
    }
  });

export const createOffer = (offer: OfferInput) => {
  return client.mutate({
    mutation: gql`
      mutation CreateOffer($offer: OfferInput!) {
        createOffer(offer: $offer) {
          ...OfferResponse
        }
      }
      ${offerFragment}
    `,
    variables: { offer: offer }
  });
};

export const updateOffer = (offerId: number, offer: Partial<OfferInput>) => {
  return client.mutate({
    mutation: gql`
      mutation UpdateOffer($id: Int!, $offer: UpdateInput!) {
        updateOffer(id: $id, offer: $offer) {
          ...OfferResponse
        }
      }
      ${offerFragment}
    `,
    variables: { id: offerId, offer: offer }
  });
};

export const approveOfferMutation = gql`
  mutation approveOffer($id: Int!) {
    approveOffer(id: $id) {
      ...OfferResponse
    }
  }
  ${offerFragment}
`;

export const denyOfferMutation = gql`
  mutation denyOffer($id: Int!) {
    denyOffer(id: $id) {
      ...OfferResponse
    }
  }
  ${offerFragment}
`;

const ARCHIVE_OFFER = gql`
  mutation ArchiveOffer($id: Int!) {
    archiveOffer(id: $id) {
      id
      archivedAt
    }
  }
`;

export const archiveOffer = (offerId: number) => {
  return client.mutate({
    mutation: ARCHIVE_OFFER,
    variables: { id: offerId }
  });
};
