import * as Sentry from "@sentry/react";
import Firebase from "firebase/app";
import "firebase/auth";
import React, { useEffect } from "react";
import { Provider, useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import NavBar from "./components/common/navbar/NavBar";
import ModalContextProvider from "./components/modals/modalcontext/ModalContextProvider";
import NoMatch from "./components/NoMatch";
import PrivateRoute from "./components/PrivateRoute";
import store, { persistor, RootState, useMapDispatch } from "./store";
import Account from "./views/Account/Account";
import AdminBusinessView from "./views/Admin/AdminBusinessView";
import AdminDeals from "./views/Admin/Deals";
import AdminUsers from "./views/Admin/Users/Users";
import Callback from "./views/Callback";
import Contact from "./views/Contact/Contact";
import DealFormPage from "./views/Deal/DealFormPage";
import Landing from "./views/Landing";
import Location from "./views/Location/Location";
import LocationFormPage from "./views/Location/LocationFormPage";
import { Locations } from "./views/Location/Locations";
import PasswordReset from "./views/PasswordReset/PasswordReset";
import Privacy from "./views/Privacy/Privacy";
import Signup from "./views/Signup";
import Terms from "./views/Terms/Terms";
import VerifyEmail from "./views/VerifyEmail/VerifyEmail";
import Footer from "./components/common/footer/Footer";

const AppRouter: React.FC = () => {
  const {
    getUserByExtId,
    getIdToken,
    setUserInfoFromFirebase
  } = useMapDispatch(dispatch => ({
    getUserByExtId: dispatch.user.getUserByExtId,
    getIdToken: dispatch.user.getIdToken,
    setUserInfoFromFirebase: dispatch.user.setUserInfoFromFirebase
  }));

  const hasManageUsersPrivileges = useSelector(
    (state: RootState) => state.user.privileges.hasManageUsersPrivileges
  );

  const refreshUserData = async () => {
    await getIdToken();
    await getUserByExtId();
  };

  useEffect(() => {
    const user = Firebase.auth().currentUser;
    if (user) {
      setUserInfoFromFirebase(user);
      refreshUserData();
    }
  }, []);

  return (
    <Router>
      <NavBar />
      <Switch>
        <Route path="/" component={Landing} exact />
        <Route path="/callback" component={Callback} exact />
        <Route path="/password-reset" component={PasswordReset} exact />
        <Route path="/verify-email" component={VerifyEmail} exact />
        <Route path="/signup" component={Signup} exact />
        <Route path="/privacy-policy" component={Privacy} exact />
        <Route path="/contact" component={Contact} exact />
        <Route path="/terms-and-conditions" component={Terms} exact />
        <PrivateRoute path="/deal/edit/:locationId/:offerId">
          <DealFormPage />
        </PrivateRoute>
        <PrivateRoute path="/adddeal" exact>
          <DealFormPage />
        </PrivateRoute>
        <PrivateRoute path="/locations" exact>
          <Locations />
        </PrivateRoute>
        <PrivateRoute path="/location/:locationId/adddeal">
          <DealFormPage />
        </PrivateRoute>
        <PrivateRoute path="/location/:locationId" exact>
          <Location />
        </PrivateRoute>
        <PrivateRoute path="/location/edit/:businessId/:locationId" exact>
          <LocationFormPage />
        </PrivateRoute>
        <PrivateRoute path="/locations/add/:businessId" exact>
          <LocationFormPage />
        </PrivateRoute>
        <PrivateRoute path="/account" exact>
          <Account />
        </PrivateRoute>
        <PrivateRoute path="/admin/deals" adminRoute={true}>
          <AdminDeals />
        </PrivateRoute>
        <PrivateRoute path="/admin/businesses" adminRoute={true}>
          <AdminBusinessView />
        </PrivateRoute>
        <PrivateRoute path="/admin/locations/:businessId" adminRoute={true}>
          <Locations />
        </PrivateRoute>
        {hasManageUsersPrivileges && (
          <PrivateRoute path="/admin/users" adminRoute={true}>
            <AdminUsers />
          </PrivateRoute>
        )}
        <Route path="*">
          <NoMatch />
        </Route>
      </Switch>
      <Footer />
    </Router>
  );
};

const App: React.FC = () => (
  <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
    <PersistGate persistor={persistor}>
      <Provider store={store}>
        <ModalContextProvider>
          <AppRouter />
        </ModalContextProvider>
      </Provider>
    </PersistGate>
  </Sentry.ErrorBoundary>
);

export default App;
