import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import React from "react";
import { useHistory } from "react-router-dom";

interface DealCreatedModalParams {
  open: boolean;
  onClose: () => void;
  locationId?: string;
  isEditingDeal: boolean;
}

export const DealCreatedDialog = ({
  open,
  onClose,
  locationId,
  isEditingDeal
}: DealCreatedModalParams) => {
  const history = useHistory();

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {isEditingDeal ? "Deal Edited" : "Deal Created"}
        {"  "}
        <CheckCircleIcon
          color="primary"
          style={{ color: "green", verticalAlign: "text-bottom" }}
        />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {`Would you like to ${isEditingDeal ? "edit" : "add"} another deal?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={() => {
            if (locationId) {
              history.replace(`/location/${locationId}/adddeal`);
            }
            window.location.reload();
            onClose();
          }}
        >
          Add another deal
        </Button>
        {isEditingDeal && (
          <Button color="primary" onClick={() => onClose()}>
            Edit another deal
          </Button>
        )}
        <Button
          color="primary"
          onClick={() => {
            history.push(locationId ? `/location/${locationId}` : "/locations");
            onClose();
          }}
        >
          Return to profile
        </Button>
      </DialogActions>
    </Dialog>
  );
};
