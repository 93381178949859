import React from "react";

const ModalContext = React.createContext<{
  isOpen: boolean;
  handleOpen: () => void;
  handleClose: () => void;
}>({
  isOpen: false,
  handleOpen: () => null,
  handleClose: () => null
});

export default ModalContext;
