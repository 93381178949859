import React from "react";
import {
  Card,
  CardActionArea,
  makeStyles,
  CardContent,
  CardMedia,
  Grid
} from "@material-ui/core";

export interface GeneralCardProps {
  imageUrl?: string;
  style?: React.CSSProperties;
  title: string;
  subTitle?: string;
  subTitleNumber: number;
  cardClickAction?: () => void;
}

const useStyles = makeStyles({
  media: {
    width: "auto",
    height: "208px"
  },
  title: {
    width: "100%",
    margin: "0",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "20px",
    color: "#000000",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  }
});

const GeneralCard: React.FC<GeneralCardProps> = ({
  title,
  subTitle,
  subTitleNumber,
  imageUrl,
  cardClickAction,
  style
}) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Card>
        <CardActionArea onClick={cardClickAction}>
          {imageUrl ? (
            <CardMedia className={classes.media} image={imageUrl} />
          ) : (
            <div
              style={{
                height: "208px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <h2>No Locations</h2>
            </div>
          )}
          <CardContent style={style}>
            <p className={classes.title}>{title}</p>
            <p>
              <span className="locationCardDealsCount">
                {subTitleNumber} {subTitle}
              </span>
            </p>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export default GeneralCard;
