import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flex: 1,
    height: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    alignItems: "flex-end",
    overflow: "hidden"
  },
  thumbsContainer: {
    padding: "40px"
  },
  thumb: {
    height: 64,
    width: 64,
    margin: "24px 24px 0px 0px",
    objectFit: "cover",
    borderRadius: "4px"
  }
});

interface ImageViewProps {
  images: string[];
}

export const ImageViewer = ({ images }: ImageViewProps) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const renderThumbnail = (image: string, index: number) => {
    const onClick = () => setSelectedIndex(index);
    const opacity = index === selectedIndex ? 0.5 : 1;

    return (
      <img
        key={index}
        src={image}
        className={classes.thumb}
        onClick={onClick}
        style={{ opacity }}
        alt={"thumbnail"}
      />
    );
  };

  const classes = useStyles();

  return (
    <div
      className={classes.container}
      style={{ backgroundImage: `url('${images[selectedIndex]}')` }}
    >
      <div className={classes.thumbsContainer}>
        {images.map(renderThumbnail)}
      </div>
    </div>
  );
};
