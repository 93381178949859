import * as React from "react";
import { Redirect, useLocation } from "react-router-dom";

const Callback: React.FunctionComponent = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  switch (query.get("mode")) {
    case "resetPassword":
      return <Redirect to={"/password-reset" + search} />;
    case "verifyEmail":
      return <Redirect to={"/verify-email" + search} />;
    default:
      return <Redirect to={"/" + search} />;
  }
};

export default Callback;
