import { Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import { Role, User } from "../../../types/generated-types";
import { RoleInput } from "../../../types/user";
import * as api from "../../../api";
import * as FormLayout from "../../../components/forms/layout/FormLayout";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";
import SelectField from "../../../components/forms/fields/selectfield/SelectField";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { isEqual } from "lodash";

const ROLE_TYPES = [
  { title: "Sales Representative", value: Role.SalesRep },
  { title: "Sales Manager", value: Role.SalesManager },
  { title: "Admin", value: Role.Admin }
];

interface EditAdminUserFormProps {
  user: User;
}

const EditAdminUserForm: React.FC<EditAdminUserFormProps> = ({ user }) => {
  const [error, setError] = useState<string>();
  const [success, setSuccess] = useState(false);

  const initialValues: RoleInput = {
    role: user?.roles?.length ? user.roles[0].role : Role.SalesRep
  };

  const schema = Yup.object({
    role: Yup.string()
      .required()
      .oneOf([Role.Admin, Role.SalesManager, Role.SalesRep])
  });

  const onSubmit = async (values: RoleInput) => {
    try {
      setError(undefined);
      setSuccess(false);

      const newRole: RoleInput = { role: values.role };
      const oldRole: RoleInput | null = user.roles?.length
        ? { role: user.roles[0].role }
        : null;

      await api.users.addUserRole(user.id, newRole);
      if (oldRole) {
        await api.users.removeUserRole(user.id, oldRole);
      }

      setSuccess(true);
    } catch (e) {
      console.log(`Error editing admin user`, error);
      setError("Error editing user.");
    }
  };

  if (success) {
    return (
      <>
        <DialogTitle>
          User Edited
          <CheckCircleIcon
            color="primary"
            style={{ color: "green", verticalAlign: "text-bottom" }}
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Successfully updated user&apos;s role.
          </DialogContentText>
        </DialogContent>
      </>
    );
  }

  return (
    <>
      <DialogTitle>Edit User</DialogTitle>
      <DialogContent>
        <Formik<RoleInput>
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, handleSubmit, values }) => (
            <Form onSubmit={handleSubmit}>
              <FormLayout.Column>
                <SelectField
                  name="role"
                  label="Role"
                  selectOptions={ROLE_TYPES}
                />
              </FormLayout.Column>
              <DialogActions>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={isSubmitting || isEqual(values, initialValues)}
                >
                  Save
                </Button>
                {error && <p style={{ color: "red" }}>{error}</p>}
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </>
  );
};

export default EditAdminUserForm;
