import React from "react";

import {
  HoursOfOperation as HoursOfOperationType,
  HoursObj
} from "../../types/generated-types";
import "./HoursOfOperation.css";
const CLOSED = "CLOSED";

interface HoursOfOperationProps {
  hours?: HoursOfOperationType;
  headerTextClassName?: string;
}

const HoursOfOperation: React.FC<HoursOfOperationProps> = ({
  hours,
  headerTextClassName
}) => {
  const formattedHours = (
    oldHourFormat: HoursObj,
    weekdayHours: HoursObj[] | null
  ) => {
    if (
      oldHourFormat.isClosed ||
      !weekdayHours?.[0].open ||
      !weekdayHours?.[0].close
    ) {
      return [CLOSED];
    }
    return weekdayHours?.map(hours => `${hours.open}-${hours.close}`);
  };
  const daysOfTheWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday"
  ];
  return (
    <div className="hoursOfOperationContainer">
      <span className={headerTextClassName}>Hours</span>
      <br />
      <div className="hoursOfOperationGrid">
        {daysOfTheWeek.map(day => (
          <React.Fragment key={day}>
            <p className="hoursOfOperationText">{day}</p>
            <div>
              {formattedHours(
                (hours as any)[`${day.toLowerCase()}`],
                (hours as any)[`${day.toLowerCase()}s`]
              )?.map(row => (
                <p className="hoursOfOperationText" key={row}>
                  {row}
                </p>
              ))}
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default HoursOfOperation;
