import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.05)",
      borderRadius: "8px",
      "&:focus": {
        outline: "none"
      }
    }
  })
);

interface TransitionModalProps {
  className?: string;
  isOpen: boolean;
  handleClose(): void;
}

const TransitionModal: React.FunctionComponent<TransitionModalProps> = ({
  children,
  className,
  isOpen,
  handleClose
}) => {
  const classes = useStyles();

  return (
    <Modal
      aria-labelledby="login"
      aria-describedby="login to the FavIT admin panel"
      className={classes.modal}
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={isOpen}>
        <div className={clsx(classes.paper, className)}>{children}</div>
      </Fade>
    </Modal>
  );
};

export default TransitionModal;
