import "./Users.css";
import React, { useEffect, useState } from "react";
import * as api from "../../../api";
import { User } from "../../../types/generated-types";
import SecondaryButton from "../../../components/forms/buttons/SecondaryButton";
import CreateAdminUserForm from "./CreateAdminUserForm";
import { Button, Dialog, Paper, Tab, Tabs } from "@material-ui/core";
import EditAdminUserForm from "./EditAdminUserForm";
import { ToggleView } from "../../../components/forms/layout/ToggleView";
import {
  downloadCSV,
  getMerchantListForExport
} from "../../../utils/downloadMerchantList";
import EditMerchantForm from "./EditMerchantForm";

export const TAB_INDEX = {
  ADMINS: 0,
  MERCHANTS: 1
};

const Users: React.FC = () => {
  const [tab, setTab] = useState(TAB_INDEX.ADMINS);

  const [users, setUsers] = useState<User[]>([]);
  const [userError, setUserError] = useState<string>();
  const [userToEdit, setUserToEdit] = useState<User>();
  const [createNewModalOpen, setCreateNewModalOpen] = useState(false);

  const [merchants, setMerchants] = useState<User[]>([]);
  const [merchantError, setMerchantError] = useState<string>();
  const [merchantToEdit, setMerchantToEdit] = useState<User>();

  const fetchUsers = async () => {
    try {
      const {
        data: { adminUsers }
      } = await api.users.getAdminUsers();
      setUsers(
        adminUsers.sort((a: User, b: User) =>
          a.lastName?.localeCompare(b.lastName || "")
        )
      );
    } catch (error) {
      setUserError("Error fetching users. Please try again.");
      console.log(`Error fetching users in admin page`, error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [createNewModalOpen, userToEdit]); // fetch new users whenever changes saved

  const fetchMerchantUsers = async () => {
    try {
      const {
        data: { merchantUsers }
      } = await api.users.getMerchantUsers();
      setMerchants(
        merchantUsers.sort((a: User, b: User) =>
          a.business?.name?.localeCompare(b.business?.name || "")
        )
      );
    } catch (error) {
      setMerchantError("Error fetching users. Please try again.");
      console.log(`Error fetching merchants in admin page`, error);
    }
  };

  useEffect(() => {
    fetchMerchantUsers();
  }, [merchantToEdit]); // fetch new users whenever changes saved]

  const AdminView = () => (
    <div className="container users-container">
      <div className="users-inner-container">
        <div className="users-header">
          <div>
            <h1>Admin Users</h1>
            <p>Manage Admins, Sales Reps, and Sales Managers.</p>
          </div>
          <SecondaryButton onClick={() => setCreateNewModalOpen(true)}>
            + Add New
          </SecondaryButton>
        </div>
        {userError ? (
          <p>{userError}</p>
        ) : (
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Roles</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {users.map(user => (
                <tr key={user.id}>
                  <td>
                    <b>
                      {user.firstName} {user.lastName}
                    </b>
                    <br />
                    {user.email}
                  </td>
                  <td>{user.roles.map(r => r.role).join(", ")}</td>
                  <td>
                    <Button
                      color="secondary"
                      onClick={() => setUserToEdit(user)}
                    >
                      Edit
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );

  const MerchantView = () => (
    <div className="container users-container">
      <div className="users-inner-container">
        <div className="users-header">
          <div>
            <h1>Merchants</h1>
          </div>
          <SecondaryButton
            onClick={() => downloadCSV(getMerchantListForExport(merchants))}
          >
            Download List
          </SecondaryButton>
        </div>
        {merchantError ? (
          <p>{merchantError}</p>
        ) : (
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Business Name</th>
                <th>Roles</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {merchants.map(merchant => (
                <tr key={merchant.id}>
                  <td>
                    <b>
                      {merchant.firstName} {merchant.lastName}
                    </b>
                    <br />
                    {merchant.email}
                  </td>
                  <td>{merchant.business?.name || "Not found"}</td>
                  <td>{merchant.roles.map(r => r.role).join(", ")}</td>
                  <td>
                    <Button
                      color="secondary"
                      onClick={() => setMerchantToEdit(merchant)}
                    >
                      Edit
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );

  return (
    <>
      <div className="users-page">
        <Paper square className="users-tabs">
          <Tabs value={tab} onChange={(_, newValue) => setTab(newValue)}>
            <Tab label="Admin Users" />
            <Tab label="Merchants" />
          </Tabs>
        </Paper>
        <ToggleView value={tab} index={TAB_INDEX.ADMINS}>
          <AdminView />
        </ToggleView>
        <ToggleView value={tab} index={TAB_INDEX.MERCHANTS}>
          <MerchantView />
        </ToggleView>
      </div>
      {/* MODALS */}
      <Dialog
        open={createNewModalOpen}
        onClose={() => setCreateNewModalOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <CreateAdminUserForm />
      </Dialog>
      <Dialog
        open={!!userToEdit}
        onClose={() => setUserToEdit(undefined)}
        maxWidth="sm"
        fullWidth
      >
        <EditAdminUserForm user={userToEdit!} />
      </Dialog>
      <Dialog
        open={!!merchantToEdit}
        onClose={() => setMerchantToEdit(undefined)}
        maxWidth="sm"
        fullWidth
      >
        <EditMerchantForm user={merchantToEdit!} />
      </Dialog>
    </>
  );
};

export default Users;
