import React from "react";
import "firebase/storage";
import { makeStyles } from "@material-ui/core";

import { DraggableProvided } from "react-beautiful-dnd";

const useStyles = makeStyles({
  imageTile: {
    position: "relative",
    height: 64,
    width: 64,
    marginRight: 24,
    borderRadius: "4px"
  },
  image: {
    height: 64,
    width: 64,
    objectFit: "cover",
    borderRadius: "4px"
  },
  closeButton: {
    position: "absolute",
    top: -8,
    right: -8
  }
});

const CloseX = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10" cy="10" r="8" fill="#0C0D32" />
    <path
      d="M7 13L13 7"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M13 13L7 7"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);

interface Props {
  url: string;
  removeImageFile: () => void;
  provided: DraggableProvided;
}

export const PreviousImageTile = ({
  url,
  removeImageFile,
  provided
}: Props) => {
  const classes = useStyles();

  const removeImage = () => {
    // TODO remove from FB
    removeImageFile();
  };

  return (
    <div
      className={classes.imageTile}
      ref={provided.innerRef}
      {...provided.dragHandleProps}
      {...provided.draggableProps}
    >
      <img className={classes.image} src={url} alt={"Thumbnail"} />
      <div className={classes.closeButton} onClick={removeImage}>
        <CloseX />
      </div>
    </div>
  );
};
