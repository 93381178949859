import { gql } from "apollo-boost";
import omit from "lodash/omit";
import { client } from "../services/apollo";
import { AccountFormValues, SignupFormValues } from "../types/user";

export const createMerchant = (values: SignupFormValues) =>
  client.mutate({
    mutation: gql`
      mutation CreateMerchant($merchant: MerchantInput!) {
        createMerchant(merchant: $merchant) {
          id
          firstName
          lastName
          email
          business {
            id
            name
          }
          roles {
            role
          }
        }
      }
    `,
    variables: { merchant: omit(values, ["password"]) }
  });

export const updateUser = (userId: number, values: AccountFormValues) =>
  client.mutate({
    mutation: gql`
      mutation UpdateUser($userId: Int!, $input: UserInput!) {
        updateUser(userId: $userId, input: $input) {
          id
          firstName
          lastName
          email
          business {
            id
            name
          }
          roles {
            role
          }
        }
      }
    `,
    variables: { userId, input: values }
  });

export const getUserByExtId = () =>
  client.query({
    query: gql`
      query {
        userByExtId {
          id
          firstName
          lastName
          email
          business {
            id
            name
          }
          roles {
            role
          }
        }
      }
    `
  });
