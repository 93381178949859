import React from "react";

import "./SimpleOffersList.css";
import SimpleDealCard from "./SimpleOfferCard";
import { Offer } from "../../types/offer";
import Spinner from "../common/Spinner";
import { Status } from "../../types/generated-types";

interface SimpleOfferListProps {
  offers: Offer[];
  rightHeaderContent?: () => JSX.Element;
  removeOffer: (id: number) => void;
  onOfferClick: (offer: Offer) => void;
  fetchingOffers?: boolean;
  selectedId?: number;
}

const SimpleOffersList: React.FC<SimpleOfferListProps> = ({
  offers,
  rightHeaderContent,
  removeOffer,
  onOfferClick,
  fetchingOffers,
  selectedId
}) => {
  const pendingOffers = offers
    .filter(o => o.status === Status.Pending)
    .sort((o1, o2) => o1.id - o2.id);
  const approvedOffers = offers
    .filter(o => o.status === Status.Approved)
    .sort((o1, o2) => o1.id - o2.id);

  const renderCard = (offer: Offer, index: number) => {
    const remove = () => {
      removeOffer(offer.id);
    };
    return (
      <SimpleDealCard
        key={index}
        offer={offer}
        remove={remove}
        onClick={() => onOfferClick(offer)}
        selected={offer.id === selectedId}
        showRedemptions={offer.status === Status.Approved}
      />
    );
  };

  return (
    <div>
      <div className="simpleOffersListHeaderContainer">
        <p className="simpleOffersListTile">
          Current Deals
          <span className="simpleOffersListNumber">
            {approvedOffers?.length ?? 0} DEALS
          </span>
        </p>
        <div>{rightHeaderContent ? rightHeaderContent() : null}</div>
      </div>
      {fetchingOffers ? <Spinner center /> : approvedOffers.map(renderCard)}

      {!!pendingOffers?.length && (
        <div className="simpleOffersListPendingContainer">
          <p className="simpleOffersListTile">
            Pending Deals
            <span className="simpleOffersListNumber">
              {pendingOffers?.length ?? 0} DEALS
            </span>
          </p>
          {pendingOffers.map(renderCard)}
        </div>
      )}
    </div>
  );
};

export default SimpleOffersList;
