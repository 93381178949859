import { Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import { User } from "../../../types/generated-types";
import * as FormLayout from "../../../components/forms/layout/FormLayout";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { isEqual } from "lodash";
import TextInput from "../../../components/forms/fields/textinput/TextInput";
import * as api from "../../../api";

interface EditMerchantFormProps {
  user: User;
}

interface EditMerchantFormSchema {
  email: string;
}

const EditMerchantForm: React.FC<EditMerchantFormProps> = ({ user }) => {
  const [error, setError] = useState<string>();
  const [success, setSuccess] = useState(false);

  const initialValues: EditMerchantFormSchema = {
    email: user?.email || ""
  };

  const schema = Yup.object({
    email: Yup.string()
      .required()
      .email()
  });

  const onSubmit = async (values: EditMerchantFormSchema) => {
    try {
      setError(undefined);
      setSuccess(false);
      await api.users.updateEmail(user.id, values.email);
      setSuccess(true);
    } catch (e) {
      console.log(`Error editing merchant user`, error);
      setError("Error editing merchant. Please try again.");
    }
  };

  if (success) {
    return (
      <>
        <DialogTitle>
          Merchant Edited
          <CheckCircleIcon
            color="primary"
            style={{ color: "green", verticalAlign: "text-bottom" }}
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Successfully updated merchant&apos;s email.
            <br />
            Merchant must now use new email to log in to their account.
          </DialogContentText>
        </DialogContent>
      </>
    );
  }

  return (
    <>
      <DialogTitle>Edit User</DialogTitle>
      <DialogContent>
        <Formik<EditMerchantFormSchema>
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, handleSubmit, values }) => (
            <Form onSubmit={handleSubmit}>
              <FormLayout.Column>
                <TextInput name="email" label="Email" />
              </FormLayout.Column>
              <DialogActions>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={isSubmitting || isEqual(values, initialValues)}
                >
                  Save
                </Button>
                {error && <p style={{ color: "red" }}>{error}</p>}
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </>
  );
};

export default EditMerchantForm;
